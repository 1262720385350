<script setup lang="ts">
import { useOverlayStore } from "@/shop/store"
import { sleep, useHttpClient } from "@/vf"
import type { AxiosError } from "axios"
import { ref, toRaw } from "vue"
import { useRouter } from "vue-router"
import { LoginOverlay } from "."
import { useShopAppConfig } from "@/shop/composables"
import { presetImages } from "@/common/assets/preset-images/preset-images"

defineExpose({
    name: "LoginOverlay",
})

const overlayStore = useOverlayStore()

// for description of what these events mean, see useAuthentication composable

/**
 * Authentication successful, user is now logged in.
 */
const onAuthenticated = () => {
    overlayStore.props?.onAuthenticated?.()
}

/**
 * Overlay was closed without authentication.
 */
const onClosed = () => {
    const fn = overlayStore.props?.onClosed ?? (() => router.push({ name: "home" }))

    fn()
}

/**
 * User has cancelled the login process by navigating away from the login overlay (e.g. by going to the registration
 * page).
 */
const onCancelled = () => {
    const fn = overlayStore.props?.onCancelled

    fn?.()
}

async function login(): Promise<void> {
    if (!email.value || !password.value) {
        error.value = "Bitte gib deine E-Mailadresse und dein Passwort ein."
        await sleep(2000)
        error.value = undefined
        return
    }

    try {
        error.value = undefined
        await http.post("login", {
            username: email.value,
            password: password.value,
            _remember_me: rememberMe.value,
        })

        const appConfig = await refreshAppConfig()
        if (appConfig.value.customAfterLoginDialog) {
            await showCustomAfterLoginDialog(
                appConfig.value.customAfterLoginDialog.text,
                appConfig.value.customAfterLoginDialog.presetImageIndex,
            )
        }

        // check if this overlay is still the active one,
        // because app config subscribers can open other overlays (i.e. user-info)
        if (toRaw(overlayStore.component) === LoginOverlay) {
            overlayStore.close()
        }

        onAuthenticated()
    } catch (exc) {
        console.error(exc)
        const e = exc as AxiosError<{ error: string }>
        error.value = e.response?.data.error ?? "Unbekannter Fehler"
    }
}

/**
 * Close the overlay without any further action.
 */
function close() {
    overlayStore.close()
    onClosed()
}

/**
 * Cancel the authentication process and leave to a different route.
 */
function cancelAndNavigate(route: string): void {
    overlayStore.close()
    onCancelled()
    router.push({ name: route })
}

/*─────────────────────────────────────┐
│   composables                        │
└─────────────────────────────────────*/
const { refreshAppConfig } = useShopAppConfig()
const http = useHttpClient()
const router = useRouter()
/*─────────────────────────────────────┐
│   refs                               │
└─────────────────────────────────────*/
const password = ref<string>()
const email = ref<string>()
const error = ref<string>()
const rememberMe = ref<boolean>()

/*─────────────────────────────────────┐
│  custom after login dialog           │
└─────────────────────────────────────*/
const afterLoginDialogText = ref<string>()
const showAfterLoginDialog = ref(false)
const loginDialogImageIndex = ref<number>()

let dismissAfterLoginDialog: () => void

function showCustomAfterLoginDialog(text: string, imageIndex?: number): Promise<void> {
    showAfterLoginDialog.value = true
    afterLoginDialogText.value = text
    loginDialogImageIndex.value = imageIndex ?? undefined

    return new Promise<void>(resolve => {
        dismissAfterLoginDialog = resolve
    })
}
</script>

<template>
    <div v-if="showAfterLoginDialog" class="login">
        <div class="login-yin login-full text-left">
            <div class="row mb-5">
                <div
                    class="col-12"
                    :class="{ 'col-md-8': loginDialogImageIndex !== undefined }"
                    v-html="afterLoginDialogText"
                ></div>
                <div v-if="loginDialogImageIndex !== undefined" class="d-flex col-12 col-md-4">
                    <img
                        style="max-height: 500px"
                        class="img-fluid mx-auto my-auto d-block"
                        :src="presetImages[loginDialogImageIndex]"
                    />
                </div>
            </div>

            <button type="button" class="btn btn-primary" @click="dismissAfterLoginDialog()">Schließen</button>
        </div>
    </div>
    <div v-else class="login">
        <button class="overlay-close" @click="close()"><i class="fa fa-times"></i></button>

        <div class="login-yin">
            <div class="login-heading">Login</div>
            <form @submit.prevent="login()">
                <div v-if="error" class="alert alert-danger">{{ error }}</div>
                <div class="login-input">
                    <i class="fas fa-fw fa-user"></i>
                    <label for="login_email" class="sr-only">E-Mailadresse</label>
                    <input
                        id="login_email"
                        v-model="email"
                        type="email"
                        placeholder="E-Mailadresse"
                        class="form-control"
                    />
                </div>

                <div class="login-input">
                    <i class="fas fa-fw fa-lock"></i>
                    <label for="login_password" class="sr-only">Passwort</label>
                    <input
                        id="login_password"
                        v-model="password"
                        type="password"
                        placeholder="Passwort"
                        class="form-control"
                    />
                </div>
                <!-- problems with json_login -->
                <!--                <div>-->
                <!--                    <label>-->
                <!--                        <input type="checkbox" checked.bind="rememberMe">-->
                <!--                        Remember Me-->
                <!--                    </label>-->
                <!--                </div>-->

                <button class="btn btn-primary my-4" type="submit">Sicherer Login</button>
            </form>
            <div>
                <RouterLink :to="{ name: 'lost-password' }" @click="cancelAndNavigate('lost-password')">
                    Passwort vergessen?
                </RouterLink>
            </div>
        </div>
        <div class="login-yang">
            <div class="login-heading">Registrierung</div>

            <p>
                Du bist noch nicht Mitglied der CANN24-Community? Dann registriere Dich jetzt, um alle unsere Leistungen
                in Anspruch nehmen zu können.
            </p>

            <RouterLink
                class="btn btn-primary my-4"
                :to="{ name: 'registration' }"
                @click="cancelAndNavigate('registration')"
            >
                Jetzt registrieren
            </RouterLink>

            <div>
                <RouterLink :to="{ name: 'legal-toc' }" @click="cancelAndNavigate('legal-toc')">Unsere AGB</RouterLink>
            </div>
        </div>
    </div>
</template>
