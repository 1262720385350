<script setup lang="ts">
import { useOverlayStore } from "@/shop/store"
import PageNavbarInner from "../page-navbar/PageNavbarInner.vue"

const overlayStore = useOverlayStore()

function toggleSidebar() {
    if (overlayStore.active) {
        overlayStore.close()
    }
}
</script>
<template>
    <div class="menu-overlay">
        <button class="overlay-close" @click="toggleSidebar()"><i class="fa fa-times"></i></button>

        <PageNavbarInner />
    </div>
</template>
