import { sleep } from "@/vf"

export async function scrollOnMobile(element: HTMLElement, edge: "top" | "bottom" = "top", offset: number = 0) {
    // check if device is a mobile device
    if (!window.matchMedia("(max-width: 768px)").matches) {
        return
    }

    // wait for the element to be rendered
    await sleep(50)
    const navbarHeight = document.querySelector(".page-navbar")?.clientHeight ?? 0
    const elementPosition =
        (edge === "top" ? element.getBoundingClientRect().top : element.getBoundingClientRect().bottom) + window.scrollY

    window.scrollTo({
        top: elementPosition - navbarHeight + offset,
        behavior: "smooth",
    })
}
