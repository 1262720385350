import type { Article } from "@/model/backend/article"
import type { ArticleVariation } from "@/model/backend/article-variation"
import type { Customer } from "@/model/backend/customer"
import type { PageElement } from "@/model/backend/page-element"
import { type AppConfig, useAppConfig } from "@/vf"

export type ShippingMethod = {
    name: string
    priceWith: number
    priceWithout: number
    dynamicPriceWith: Record<string, number>
    subtitle: string
    title: string
    options: {
        title: string
        subtitle: string
        name: string
        price: number
    }[]
}

export type SupportedInfo = "gender" | "eventAnswer" | "acknowledgeCanG"

export interface AppConfigArticleVariation extends ArticleVariation {
    childArticle: AppConfigArticle
}

export interface AppConfigArticle extends Article {
    showLowStockWarning: boolean
    price: number
    allow: boolean
    isVariationArticle: boolean
    variations: AppConfigArticleVariation[]
    isShowcasePromoted: boolean
    colorCategory: string
}

export interface ShopAppConfig {
    showPrices: boolean
    requestUserInfos?: SupportedInfo[]
    tooManyOrdersWarning: boolean
    offline: boolean
    offlineText: string
    articles: AppConfigArticle[]
    user: Customer
    shippingMethods: ShippingMethod[]
    payment: {
        name: string
        subtitle: string
        title: string
    }[]
    startPageArticles: Article[]
    startPageFeatured: Required<PageElement> & { visible: boolean }
    hasBlogPosts: boolean
    customAfterLoginDialog?: {
        text: string
        presetImageIndex: number
    }
    customEnterShopDialog?: {
        text: string
        presetImageIndex: number
    }
}

export function useShopAppConfig() {
    return useAppConfig<AppConfig & ShopAppConfig>()
}
