<script setup lang="ts">
import { WizardAnswer, WizardCustomStep, WizardQuestion, WizardStore } from "@/shop/components/wizard"
import { createWizardStore } from "@/shop/components/wizard/wizard-store"
import { useCartStore } from "@/shop/store"
import { watch } from "vue"
import GematikAppBadges from "../GematikAppBadges.vue"
import CopyInputField from "./CopyInputField.vue"
import PharmacyAddress from "./PharmacyAddress.vue"
import PostfachAddress from "./PostfachAddress.vue"
import PrescriptionUploadList from "./PrescriptionUploadList.vue"

const cartStore = useCartStore()
const wizardStore = createWizardStore()
watch(wizardStore.answers, () => {
    cartStore.prescriptionTransmission = wizardStore.answers["transmission"]
})
</script>

<template>
    <WizardStore :store="wizardStore">
        <WizardQuestion id="prescription_type" label="Hast du ein Papier-Rezept oder ein E-Rezept?">
            <template #options>
                <WizardAnswer id="email" label="Digitalisierte Verordnung">
                    <template #subtitle>Telemedizinische Plattformen verwenden diese Möglichkeit</template>
                    <template #next>
                        <WizardQuestion id="transmission" label="Wie wird die digitalisierte Verordnung übermittelt?">
                            <template #options>
                                <WizardAnswer id="email/link" label="Es wird ein Link zum Abruf des Rezeptes geteilt">
                                    <template #next>
                                        <p>
                                            Bitte teile dem Arzt oder telemedizinischen Anbieter unsere E-Mailadresse
                                            mit.
                                        </p>

                                        <CopyInputField value="info@cann24.de" />

                                        <RouterLink :to="{ name: 'checkout' }" class="btn btn-primary mt-4">
                                            Weiter
                                        </RouterLink>
                                    </template>
                                </WizardAnswer>
                                <WizardAnswer
                                    id="email/encrypted"
                                    label="Das Rezept wird durch den Arzt verschlüsselt per Mail versendet"
                                >
                                    <template #next>
                                        <p>
                                            Bitte teile dem Arzt oder telemedizinischen Anbieter unsere E-Mailadresse
                                            mit.
                                        </p>

                                        <CopyInputField value="info@cann24.de" />

                                        <RouterLink :to="{ name: 'checkout' }" class="btn btn-primary mt-4">
                                            Weiter
                                        </RouterLink>
                                    </template>
                                </WizardAnswer>
                            </template>
                        </WizardQuestion>
                    </template>
                </WizardAnswer>
                <WizardAnswer id="paper" label="Papier">
                    <template #next>
                        <WizardQuestion id="transmission" label="Wie kommt das Rezept zu uns?">
                            <template #text>
                                Bitte beachte, dass wir erst mit der Herstellung beginnen können, wenn das Papier-Rezept
                                im Original vorliegt!
                            </template>
                            <template #options>
                                <WizardAnswer id="paper/letter" label="Ich sende es selbst per Post">
                                    <template #label></template>
                                    <template #next>
                                        <WizardCustomStep id="post_address">
                                            <template #default="{ next }">
                                                <p>Alles klar. Bitte sende dein Rezept an folgende Adresse:</p>

                                                <PharmacyAddress class="pl-4" />

                                                <p class="mt-4">oder an unser Postfach:</p>

                                                <PostfachAddress class="pl-4" />

                                                <div class="mt-4">
                                                    <button class="btn btn-primary" @click="next">Weiter</button>
                                                </div>
                                            </template>
                                            <template #next>
                                                <PrescriptionUploadList></PrescriptionUploadList>
                                            </template>
                                        </WizardCustomStep>
                                    </template>
                                </WizardAnswer>
                                <WizardAnswer
                                    id="paper/letter-doctor"
                                    label="Es wird direkt von meinem Arzt per Post zu euch gesendet"
                                >
                                    <template #next>
                                        <WizardCustomStep id="doctor_post_address">
                                            <template #default="{ next }">
                                                <p>
                                                    Alles klar. Bitte gib deinem Arzt unsere Adresse durch, damit er das
                                                    Rezept direkt an uns senden kann:
                                                </p>

                                                <PharmacyAddress class="pl-4" />

                                                <button type="button" class="btn btn-primary" @click="next">
                                                    Weiter
                                                </button>
                                            </template>
                                            <template #next>
                                                <PrescriptionUploadList />
                                            </template>
                                        </WizardCustomStep>
                                    </template>
                                </WizardAnswer>
                                <WizardAnswer id="paper/in-person" label="Ich bringe es persönlich vorbei">
                                    <template #next>
                                        <PrescriptionUploadList></PrescriptionUploadList>
                                    </template>
                                </WizardAnswer>
                            </template>
                        </WizardQuestion>
                    </template>
                </WizardAnswer>
                <WizardAnswer id="digital" label="E-Rezept">
                    <template #next>
                        <WizardQuestion id="transmission" label="Wie möchtest du das Rezept übermitteln?">
                            <template #text>
                                Beachte bitte, dass wir erst mit der Herstellung beginnen können, wenn das E-Rezept
                                erfolgreich abgerufen werden konnte.
                            </template>
                            <template #options>
                                <WizardAnswer
                                    id="digital/token"
                                    label="Ich habe einen E-Rezept-Token zum Upload vorliegen"
                                >
                                    <template #next>
                                        <WizardQuestion label="Sieht dein Token so aus?">
                                            <template #text>
                                                <img
                                                    src="@/shop/assets/digital-token-example.jpg"
                                                    alt="E-Rezept-Token"
                                                    style="max-width: 500px; width: 100%"
                                                    class="img-fluid"
                                                />
                                            </template>

                                            <template #options>
                                                <WizardAnswer label="Ja, mein E-Rezept-Token sieht so aus">
                                                    <template #next>
                                                        <p>
                                                            Nach Prüfung des Rezeptes erhältst du eine
                                                            Auftragsbestätigung und die Rechnung in deinem Profil
                                                            angezeigt.
                                                        </p>
                                                        <PrescriptionUploadList></PrescriptionUploadList>
                                                    </template>
                                                </WizardAnswer>
                                                <button
                                                    class="wizard-button"
                                                    @click="wizardStore.resetAnswer('transmission')"
                                                >
                                                    Nein, mein Rezept-Token sieht anders aus
                                                </button>
                                            </template>
                                        </WizardQuestion>
                                    </template>
                                </WizardAnswer>
                                <WizardAnswer id="digital/app" label="Ich schicke es euch über die E-Rezept App">
                                    <template #next>
                                        <p>
                                            Wähle die „apotheke am inselpark“ in der E-Rezept App der Gematik aus und
                                            folge den Anweisungen. Nach Prüfung des Rezeptes erhältst du eine
                                            Auftragsbestätigung und die Rechnung in deinem Profil angezeigt. Wenn du die
                                            App noch nicht hast, kannst du sie dir hier für dein Smartphone runterladen.
                                        </p>

                                        <GematikAppBadges />

                                        <RouterLink :to="{ name: 'checkout' }" class="btn btn-primary mt-4">
                                            Weiter
                                        </RouterLink>
                                    </template>
                                </WizardAnswer>
                                <WizardAnswer id="digital/kim" label="Mein Arzt schickt es euch über die KIM-Adresse">
                                    <template #next>
                                        <p>
                                            Teile deinem Arzt unsere KIM-Adresse mit. Beachte, dass wir erst eine
                                            Rezeptprüfung und Auftragsbestätigung durchführen können, wenn deine
                                            Verordnung für uns einsehbar ist und du auch erst dann eine Rechnung in
                                            deinem Profil angezeigt bekommst.
                                        </p>

                                        <CopyInputField value="inselpark.hamburg@gedisa.kim.telematik" />

                                        <RouterLink :to="{ name: 'checkout' }" class="btn btn-primary mt-4">
                                            Weiter
                                        </RouterLink>
                                    </template>
                                </WizardAnswer>
                                <WizardAnswer
                                    id="digital/health-card"
                                    label="Ich möchte das E-Rezept über meine Gesundheitskarte in der Apotheke einlesen lassen"
                                >
                                    <template #next>
                                        <p>
                                            Bitte komm bei uns vorbei, damit wir deine Gesundheitskarte einlesen können.
                                        </p>

                                        <PharmacyAddress class="pl-4" />

                                        <p>
                                            Beachte, dass wir erst eine Rezeptprüfung und Auftragsbestätigung
                                            durchführen können, wenn deine Verordnung für uns einsehbar ist und du auch
                                            erst dann eine Rechnung in deinem Profil angezeigt bekommst.
                                        </p>

                                        <RouterLink :to="{ name: 'checkout' }" class="btn btn-primary mt-4">
                                            Weiter
                                        </RouterLink>
                                    </template>
                                </WizardAnswer>
                            </template>
                        </WizardQuestion>
                    </template>
                </WizardAnswer>
            </template>
        </WizardQuestion>
    </WizardStore>
</template>
