import { defineStore } from "pinia"
import { ref, type Component } from "vue"

export const useOverlayStore = defineStore("overlays", () => {
    const active = ref(false)
    const component = ref<Component>()
    const props = ref<Record<string, any>>()

    function open(newComponent: Component, newProps: any = null) {
        active.value = true
        component.value = newComponent
        props.value = newProps

        document.body.classList.add("overlay-visible")
    }

    function close() {
        active.value = false

        document.body.classList.remove("overlay-visible")
    }

    return { active, component, props, open, close } as const
})
