<script setup lang="ts">
import CheckoutTimeline from "@/shop/components/checkout/CheckoutTimeline.vue"
import { useShopAppConfig } from "@/shop/composables"
import { useCartStore } from "@/shop/store/useCartStore"
import { useRouter } from "vue-router"

const cartStore = useCartStore()
const { appConfig } = useShopAppConfig()
const router = useRouter()

function selectPaymentMethod(payment: any) {
    cartStore.paymentMethod = payment.name
    router.push({ name: "delivery" })
}
</script>

<template>
    <div class="container my-5">
        <div class="small text-muted mb-5 pb-3">Home / Warenkorb</div>

        <h2>Übersicht</h2>
        <div class="mb-3">
            <h1>Zahlungsart</h1>
        </div>

        <CheckoutTimeline :step="2"></CheckoutTimeline>

        <template v-for="payment in appConfig.payment" :key="payment.name">
            <button class="wizard-button" @click="selectPaymentMethod(payment)">
                <b>{{ payment.title }}</b>
                <div class="small">{{ payment.subtitle }}</div>
            </button>
        </template>
    </div>
</template>
