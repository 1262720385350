<script setup lang="ts">
import type { Order } from "@/model/backend/order"
import type { OrderState } from "@/model/order-state"
import { computed } from "vue"

const props = defineProps<{
    order: Order
}>()

type TimelineStep = {
    name: string
    done: boolean
    labelFuture: string
    labelDone: string
}

const states = computed<TimelineStep[]>(() => {
    const states: TimelineStep[] = []

    props.order.reviewed

    states.push({
        name: "reviewed",
        done: props.order.reviewed ?? false,
        labelFuture: "Bestellung nicht geprüft",
        labelDone: "Bestellung geprüft",
    })

    states.push({
        name: "prescription_checked",
        done: props.order.prescriptionChecked ?? false,
        labelFuture: "Rezept nicht geprüft",
        labelDone: "Rezept geprüft",
    })

    states.push({
        name: "payment_received",
        done: props.order.paymentReceived ?? false,
        labelFuture: "Zahlung nicht erhalten",
        labelDone: "Zahlung erhalten",
    })

    states.push({
        name: "manufactured",
        done: (["waiting_for_release", "ready_for_pickup", "ready_for_shipping", "completed"] as OrderState[]).includes(
            props.order.state ?? "new",
        ),
        labelFuture: "Nicht hergestellt",
        labelDone: "Hergestellt",
    })

    if (props.order.isPickup) {
        states.push({
            name: "ready_for_pickup",
            done: props.order.state === "completed" || props.order.state === "ready_for_pickup",
            labelFuture: "Nicht abholbereit",
            labelDone: "Abholbereit",
        })
    } else {
        states.push({
            name: "shipped",
            done: props.order.state === "completed",
            labelFuture: "Nicht versendet",
            labelDone: "Versendet",
        })
    }

    states.push({
        name: "completed",
        done: props.order.state === "completed",
        labelFuture: "Nicht abgeschlossen",
        labelDone: "Abgeschlossen",
    })

    return states
})
</script>

<template>
    <div class="timeline">
        <div v-for="(state, index) in states" :key="state.name" class="timeline-item" :class="{ done: state.done }">
            <div class="timeline-item-icon">
                <i v-if="state.done" class="fa fa-check"></i>
                <span v-else>{{ index + 1 }}</span>
            </div>
            <div class="timeline-item-content">
                {{ state.done ? state.labelDone : state.labelFuture }}
            </div>
        </div>
    </div>
</template>

<style scoped>
.timeline {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border-left: 1px solid var(--primary);
    margin-left: 0.8rem;
    padding-top: 0.8rem;
}

.timeline-item {
    display: flex;
    gap: 0.1rem;
}

.timeline-item-icon {
    border: 1px solid var(--primary);
    background-color: var(--body-bg);
    color: var(--primary);
    width: 1.6rem;
    height: 1.6rem;
    display: grid;
    place-content: center;
    border-radius: 50%;
    transform: translateX(-0.8rem);
}

.timeline-item-content {
    color: #c9c9c9;
}

.timeline-item.done .timeline-item-content {
    color: var(--primary);
}

.timeline-item.done .timeline-item-icon {
    background-color: var(--primary);
    color: var(--body-bg);
}
</style>
