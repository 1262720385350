import { injectMandatory } from "@/vf"
import { provide, reactive, type Ref } from "vue"

/*
 * Storage for all answers
 */
export type WizardStore = ReturnType<typeof createWizardStore>

export function createWizardStore() {
    const answers = reactive<Record<string, string>>({})
    const stack = reactive<string[]>([])

    function setAnswer(questionId: string, answer: string) {
        answers[questionId] = answer

        stack.push(questionId)
    }

    function getAnswer(questionId: string) {
        return answers[questionId]
    }

    function resetAnswer(questionId: string) {
        delete answers[questionId]

        // remove this question from the stack
        const index = stack.indexOf(questionId)
        if (index !== -1) {
            stack.splice(index, 1)
        }

        // reset all answers after this question
        for (let i = stack.length - 1; i >= index; i--) {
            resetAnswer(stack[i])
        }
    }

    function hasAnsweredQuestion(questionId: string) {
        return questionId in answers
    }

    function hasAnswered(questionId: string, answerId: string) {
        return answers[questionId] === answerId
    }

    return {
        setAnswer,
        getAnswer,
        resetAnswer,
        hasAnsweredQuestion,
        hasAnswered,
        answers,
    } as const
}

export function provideWizardStore(store: WizardStore) {
    provide("wizardStore", store)
}

export function useWizardStore(): WizardStore {
    return injectMandatory<WizardStore>("wizardStore", "WizardQuestion must be used inside a WizardStore")
}

/*
 * Helpers to provide question ids to the answers
 */
type QuestionIdType = Ref<string>
export function provideQuestionId(questionId: QuestionIdType) {
    provide("wizardQuestionId", questionId)
}

export function useQuestionId() {
    return injectMandatory<QuestionIdType>("wizardQuestionId", "WizardAnswer must be used inside a WizardQuestion")
}
