<script setup lang="ts">
import footer01Url from "./footer-01.svg"
import footer02Url from "./footer-02.svg"
import footer03Url from "./footer-03.svg"
import footer04Url from "./footer-04.svg"
import footer05Url from "./footer-05.svg"
import tertiaryFooterBg from "./footer-tertiary.png"
import footerBakUrl from "./footer_bak.jpg"
import footerLegalUrl from "./footer_legal.jpg"
import footerQmUrl from "./footer_qm.jpg"

const year = new Date().getFullYear()
</script>

<template>
    <div class="page-tertiary-footer" :style="{ backgroundImage: `url(${tertiaryFooterBg})` }">
        <div class="footer-item">
            <img :src="footer01Url" />
            <div>Labor Qualität</div>
        </div>

        <div class="footer-item">
            <img :src="footer02Url" />
            <div>Arzneimittel Zulassung</div>
        </div>

        <div class="footer-item">
            <img :src="footer03Url" />
            <div>Premium Upgrade</div>
        </div>

        <div class="footer-item">
            <img :src="footer04Url" />
            <div>Hohe Sicherheit</div>
        </div>

        <div class="footer-item">
            <img :src="footer05Url" />
            <div>Schnelle Lieferung</div>
        </div>
    </div>

    <div class="page-secondary-footer">
        <div class="row">
            <div class="col-md-3">
                <h6>CANN24</h6>

                <p>
                    apotheke am inselpark
                    <br />
                    Neuenfelder Str. 31 &middot; 21109 Hamburg
                    <br />
                    Hotline 040 30 23 86 99 24
                </p>

                <p>
                    <b>Postfach:</b><br />
                    apotheke am inselpark<br />
                    Postfach 93 50 23<br />
                    21094 Hamburg
                </p>

                <!--                <div class="d-flex justify-content-between" style="gap: 1rem">-->
                <!--                    <img src="../../static/icons/payment/visa.svg" alt="Visa">-->
                <!--                    <img src="../../static/icons/payment/paypal.svg" alt="Paypal">-->
                <!--                    <img src="../../static/icons/payment/mastercard.svg" alt="Mastercard">-->
                <!--                    <img src="../../static/icons/payment/klarna.svg" alt="Karna">-->
                <!--                    <img src="../../static/icons/payment/maestro.svg" alt="Maestro">-->
                <!--                </div>-->
            </div>
            <div class="col-md-6">
                <h6>Einfach &amp; Sicher</h6>

                <p>
                    Wir möchten Dir den Erwerb Deiner Arzneimittel so einfach wie möglich machen und versenden als
                    zertifizierte Versandapotheke Deine Bestellung per Über-Nacht-Express an Deine Adresse.
                </p>

                <div>
                    <img :src="footerQmUrl" width="113" height="80" alt="" class="mr-4 mb-3 img-fluid" />
                    <a
                        href="https://versandhandel.dimdi.de/websearch/servlet/Gate?accessid=dimdi_var&term=849ab145e8b9799dd81f0f65cb83f8fc"
                        target="_blank"
                    >
                        <img :src="footerLegalUrl" width="96" height="80" alt="" class="mr-4 mb-3 img-fluid" />
                    </a>
                    <img :src="footerBakUrl" width="242" height="80" alt="" class="mr-4 mb-3 img-fluid" />
                </div>
            </div>
            <div class="col-md-3">
                <h6>Informationen</h6>

                <ul class="slim-ul">
                    <li><RouterLink :to="{ name: 'faq' }">FAQ</RouterLink></li>
                    <li><RouterLink :to="{ name: 'id-check' }">Patienten ID Prüfen</RouterLink></li>
                    <li><RouterLink :to="{ name: 'legal-imprint' }">Impressum</RouterLink></li>
                    <li><RouterLink :to="{ name: 'legal-data-privacy' }">Datenschutzerklärung</RouterLink></li>
                    <li><RouterLink :to="{ name: 'legal-revocation' }">Widerrufsbelehrung</RouterLink></li>
                    <li><RouterLink :to="{ name: 'legal-toc' }">AGB</RouterLink></li>
                </ul>
            </div>
        </div>
    </div>

    <div class="page-footer">
        <div>&copy; Copyright {{ year }} apotheke am inselpark e.K. Alle Rechte vorbehalten.</div>
        <!--        <div class="page-footer-links-row">-->
        <!--            <ul class="page-footer-links mb-0 mr-3">-->
        <!--                -->
        <!--            </ul>-->
        <!--            <div class="page-footer-social">-->
        <!--                &lt;!&ndash;                <i class="fab fa-twitter mx-1"></i>&ndash;&gt;-->
        <!--                &lt;!&ndash;                <i class="fab fa-facebook-f mx-1"></i>&ndash;&gt;-->
        <!--                <a href="https://instagram.com/cann24.de" target="_blank"><i class="fab fa-instagram mx-1"></i></a>-->
        <!--            </div>-->
        <!--        </div>-->
    </div>
</template>
