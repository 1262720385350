<script setup lang="ts">
// generated from file:///input/au_src/shop/elements/page-navbar/page-navbar-inner.ts
import { useShopAppConfig } from "@/shop/composables"
import { useAuthentication } from "@/shop/composables/useAuthentication"
import { useOverlayStore } from "@/shop/store"
import { computed } from "vue"
import { useRoute } from "vue-router"
import userSvgUrl from "./user.svg"
/*─────────────────────────────────────┐
│   props                              │
└─────────────────────────────────────*/
/*─────────────────────────────────────┐
│   functions                          │
└─────────────────────────────────────*/
function closeOverlay(): void {
    overlayStore.close()
}
/*─────────────────────────────────────┐
│   composables                        │
└─────────────────────────────────────*/
const route = useRoute()
const routeName = computed(() => route.name?.toString() ?? "")

const { appConfig } = useShopAppConfig()
const overlayStore = useOverlayStore()
const auth = useAuthentication()
/*─────────────────────────────────────┐
│   refs                               │
└─────────────────────────────────────*/
/*─────────────────────────────────────┐
│   setup                              │
└─────────────────────────────────────*/
</script>

<!-- generated from file:///input/au_src/shop/elements/page-navbar/page-navbar-inner.html -->
<template>
    <ul class="page-navbar-menu">
        <li>
            <RouterLink :to="{ name: 'home' }" :class="{ active: routeName === 'home' }" @click="closeOverlay()">
                Home
            </RouterLink>
        </li>
        <li>
            <RouterLink
                :to="{ name: 'articles' }"
                :class="{ active: ['article', 'articles'].includes(routeName) }"
                @click="closeOverlay()"
            >
                Sortiment
            </RouterLink>
        </li>
        <li v-if="appConfig.hasBlogPosts">
            <RouterLink
                :to="{ name: 'blog' }"
                :class="{ active: ['blog', 'blog-post'].includes(routeName) }"
                @click="closeOverlay()"
            >
                Neuigkeiten
            </RouterLink>
        </li>
        <li>
            <RouterLink
                :to="{ name: 'about-us' }"
                :class="{ active: routeName === 'about-us' }"
                @click="closeOverlay()"
            >
                Über uns
            </RouterLink>
        </li>
        <li>
            <RouterLink :to="{ name: 'contact' }" :class="{ active: routeName === 'contact' }" @click="closeOverlay()">
                Kontakt
            </RouterLink>
        </li>
        <li class="d-none d-md-inline">
            <a v-if="!appConfig.user" href="#" @click="auth.openLogin()">
                <img :src="userSvgUrl" width="16" height="16" alt="Login" />
                Login
            </a>
            <RouterLink
                v-if="appConfig.user"
                :to="{ name: 'my-account' }"
                :class="{ active: routeName === 'my-account' }"
            >
                <img :src="userSvgUrl" width="16" height="16" alt="Mein Konto" />
                Profil
            </RouterLink>
        </li>
    </ul>

    <div class="page-navbar-social">
        <div class="page-navbar-social-follow-us">Follow us</div>

        <!--        <div class="ml-3"><img src="./facebook.svg" width="16" height="16" alt="Facebook"></div>-->
        <div class="ml-3">
            <a href="https://instagram.com/cann24official" target="_blank">
                <img src="./instagram.svg" width="16" height="16" alt="Instagram" />
            </a>
        </div>
        <!--        <div class="ml-3"><img src="./youtube.svg" width="16" height="16" alt="YouTube"></div>-->
    </div>
</template>
