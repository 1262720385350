<script setup lang="ts">
import { scrollOnMobile } from "@/shop/pages/checkout/scroll-util"
import { computed, onMounted, ref } from "vue"
import { provideQuestionId, useWizardStore } from "./wizard-store"

const props = defineProps<{
    id?: string
    label?: string
}>()

// if no explicit id is given, use the label instead
const id = computed(() => {
    const id = props.id
    const label = props.label

    if (!id && !label) {
        console.error("Please provide an id or label property")
    }

    return id ?? label!
})

// provide the question id to ajdacent answers
provideQuestionId(id)

const store = useWizardStore()

const htmlElement = ref<HTMLDivElement>()
onMounted(() => {
    scrollOnMobile(htmlElement.value!, "top", -50)
})
</script>

<template>
    <div ref="htmlElement">
        <template v-if="!store.hasAnsweredQuestion(id)">
            <h4>
                <slot name="question">{{ props.label }}</slot>
            </h4>

            <slot name="text"></slot>
        </template>

        <slot name="options"></slot>
    </div>
</template>
