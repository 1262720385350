<script setup lang="ts">
import { scrollOnMobile } from "@/shop/pages/checkout/scroll-util"
import { useCartStore } from "@/shop/store"
import { onMounted, ref } from "vue"

withDefaults(
    defineProps<{
        step?: number
    }>(),
    {
        step: 1,
    },
)

type CheckoutStep = {
    num: number
    name: string
    route: string
    shouldNavigate: () => boolean
}

function makeStep(num: number, name: string, route: string, shouldNavigate: () => boolean = () => true): CheckoutStep {
    return {
        num,
        name,
        route,
        shouldNavigate,
    }
}

const cartStore = useCartStore()

const steps = [
    makeStep(1, "Warenkorb", "cart"),
    makeStep(2, "Bezahlung", "payment"),
    makeStep(3, "Versand", "delivery"),
    makeStep(
        4,
        "Lieferung",
        "delivery-address",
        () => cartStore.shippingMethod !== "pickup" && cartStore.shippingMethod !== "pickup_service",
    ),
    makeStep(5, "Rezept", "prescription"),
    makeStep(6, "Check-Out", "checkout"),
]

const htmlElement = ref<HTMLDivElement>()
onMounted(async () => {
    scrollOnMobile(htmlElement.value!, "bottom", -20)
})
</script>

<template>
    <div ref="htmlElement" class="checkout-timeline">
        <template v-for="s in steps" :key="s.name">
            <div v-if="step >= s.num" class="checkout-timeline-text active">
                <template v-if="s.shouldNavigate()">
                    <RouterLink :to="{ name: s.route }">
                        {{ s.name }}
                    </RouterLink>
                </template>
                <template v-else>
                    {{ s.name }}
                </template>
            </div>
        </template>
        <template v-for="s in steps" :key="s.name">
            <div v-if="step < s.num" class="checkout-timeline-text">
                {{ s.name }}
            </div>
        </template>
        <div v-for="s in steps" :key="s.name" :class="'checkout-timeline-number ' + (step >= s.num ? 'active' : '')">
            {{ s.num }}
        </div>

        <div class="checkout-timeline-line"></div>
    </div>
</template>

<style scoped lang="scss">
@import "@/shop/assets/style/variables";

$checkout-line-number-size: 1.6rem;
$checkout-line-steps: 6;

.checkout-timeline {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-template-rows: repeat($checkout-line-steps, 1fr);
    grid-gap: 1rem 0.5rem;

    .checkout-timeline-text {
        grid-column: 2;
        z-index: 2;
        color: #c9c9c9;

        @for $i from 1 through $checkout-line-steps {
            &:nth-child(#{$i}) {
                grid-row: $i;
            }
        }

        &.active {
            color: $primary;
        }
    }

    .checkout-timeline-number {
        grid-column: 1;
        border: 1px solid $primary;
        background-color: $body-bg;
        color: $primary;
        width: $checkout-line-number-size;
        height: $checkout-line-number-size;
        display: grid;
        place-content: center;
        border-radius: 50%;
        z-index: 2;

        @for $i from 1 through $checkout-line-steps {
            &:nth-child(#{$i + $checkout-line-steps}) {
                grid-row: $i;
            }
        }

        &.active {
            background-color: $primary;
            color: #fdfdfd;
        }
    }

    .checkout-timeline-line {
        grid-row: 1 / span $checkout-line-steps;
        grid-column: 1;
        border-left: 1px solid $primary;
        transform: translateX(calc(#{$checkout-line-number-size / 2} - 1px));
        z-index: 1;
    }

    @include media-breakpoint-up(md) {
        grid-template-columns: repeat($checkout-line-steps, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-gap: 0.5rem 0;

        .checkout-timeline-text {
            justify-self: center;

            @for $i from 1 through $checkout-line-steps {
                &:nth-child(#{$i}) {
                    grid-row: 1;
                    grid-column: $i;
                }
            }
        }

        .checkout-timeline-number {
            justify-self: center;

            @for $i from 1 through $checkout-line-steps {
                &:nth-child(#{$i + $checkout-line-steps}) {
                    grid-row: 2;
                    grid-column: $i;
                }
            }
        }

        .checkout-timeline-line {
            grid-row: 2;
            grid-column: 1 / span $checkout-line-steps;
            border-left: none;
            border-top: 1px solid $primary;
            transform: translateY(calc(#{$checkout-line-number-size / 2} - 1px));
            z-index: 1;
        }
    }
}
</style>
