<script setup lang="ts">
import { useShopAppConfig } from "@/shop/composables"
import { useOverlayStore } from "@/shop/store"
import { presetImages } from "@/common/assets/preset-images/preset-images"

const { appConfig } = useShopAppConfig()
const overlayStore = useOverlayStore()

function close() {
    overlayStore.close()
}
</script>
<template>
    <div class="login overlay-transparent">
        <div class="login-yin login-full text-left" style="max-height: 80vh; overflow-y: auto">
            <div class="row">
                <div
                    class="col-12"
                    :class="{ 'col-md-8': appConfig.customEnterShopDialog?.presetImageIndex !== undefined }"
                >
                    <div v-html="appConfig.customEnterShopDialog?.text"></div>
                </div>
                <div
                    v-if="appConfig.customEnterShopDialog?.presetImageIndex !== undefined"
                    class="d-flex col-12 col-md-4"
                >
                    <img
                        style="max-height: 500px"
                        class="img-fluid mx-auto my-auto d-block"
                        :src="presetImages[appConfig.customEnterShopDialog?.presetImageIndex]"
                    />
                </div>
            </div>
            <button type="button" class="btn btn-primary mt-5" @click="close()">Schließen</button>
        </div>
    </div>
</template>
