<script setup lang="ts">
import { useWizardStore } from "./wizard-store"

const props = defineProps<{
    id: string
}>()

const store = useWizardStore()
function next() {
    store.setAnswer(props.id, "next")
}
</script>

<template>
    <template v-if="!store.hasAnsweredQuestion(props.id)">
        <slot v-bind="{ next }"></slot>
    </template>

    <template v-else>
        <slot name="next"></slot>
    </template>
</template>
