import ShopAlertDialog from "@/shop/components/dialogs/ShopAlertDialog.vue"
import ShopConfirmDialog from "@/shop/components/dialogs/ShopConfirmDialog.vue"
import { useDialogs } from "@/vf"

export function useShopDialogs() {
    const { open } = useDialogs()

    async function confirm(title: string, message: string): Promise<boolean> {
        return (
            await open({
                component: ShopConfirmDialog,
                props: {
                    title,
                    message,
                },
            })
        ).output as boolean
    }

    async function alert(title: string, message: string): Promise<void> {
        await open({
            component: ShopAlertDialog,
            props: {
                title,
                message,
                buttons: [],
            },
        })
    }

    return {
        confirm,
        alert,
    }
}
