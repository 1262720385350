<script setup lang="ts">
import type { Order } from "@/model/backend/order"
import { userAllowedPrescriptionUploadStates } from "@/model/order-state"
import { useShopDialogs } from "@/shop/composables/useShopDialogs"
import { useApp, useUpload } from "@/vf"
import { computed, ref } from "vue"

const props = defineProps<{ order: Order }>()
const emit = defineEmits<{
    (e: "uploaded", order: Order): void
}>()
const { apiLink } = useApp()
const uploading = ref(false)
const dialogs = useShopDialogs()

async function uploadPrescription(order: Order): Promise<void> {
    if (order.uploads?.length) {
        if (
            !(await dialogs.confirm(
                "Achtung",
                "Du hast bereits ein Rezept für diese Bestellung hochgeladen. Möchtest Du ein weiteres Rezept hochladen?",
            ))
        ) {
            return
        }
    }

    try {
        const upload = useUpload({
            url: `/order/${order.id}/upload-prescription`,
            accept: "image/*,application/pdf",
        })
        await upload.started
        uploading.value = true
        await upload.finished

        dialogs.alert("Rezept hochgeladen", "Vielen Dank. Dein Rezept wurde erfolgreich hochgeladen.")

        emit("uploaded", order)
    } finally {
        uploading.value = false
    }
}

const canUploadPrescriptions = computed(() => userAllowedPrescriptionUploadStates.includes(props.order.state!))
</script>

<template>
    <a
        v-for="upload in order.uploads"
        :key="upload.id"
        class="pl-md-1"
        target="_blank"
        :href="apiLink('shop/my-account/upload/' + upload.id)"
    >
        <img src="@/shop/assets/icons/pdf.png" width="20" />
    </a>

    <button
        v-if="canUploadPrescriptions"
        type="button"
        :class="
            'btn btn-primary-filled ' +
            (!order.uploads || order.uploads.length === 0 ? 'd-block' : 'd-block d-md-none mb-4 mt-3 my-md-0')
        "
        @click="uploadPrescription(order)"
    >
        <i v-if="uploading" class="fa fa-circle-notch fa-spin"></i>
        <span v-else>Upload</span>
    </button>

    <button
        v-if="order.uploads && order.uploads.length > 0 && canUploadPrescriptions"
        type="button"
        class="border-0 text-primary bg-transparent p-2 d-none d-md-inline-block"
        title="weiteres Rezept hochladen"
        @click="uploadPrescription(order)"
    >
        <i v-if="uploading" class="fa fa-circle-notch fa-spin"></i>
        <span v-else><i class="fa fa-fw fa-upload"></i></span>
    </button>
</template>
