<script setup lang="ts">
import CheckoutTimeline from "@/shop/components/checkout/CheckoutTimeline.vue"
import { useShopAppConfig, type ShippingMethod } from "@/shop/composables"
import { useCartStore } from "@/shop/store/useCartStore"
import { $currency } from "@/vf"
import { computed } from "vue"
import { useRouter } from "vue-router"

const cartStore = useCartStore()
const { appConfig } = useShopAppConfig()
const router = useRouter()

// payment selection is a previous step and hasn't been completed, so navigate back
if (!cartStore.paymentMethod) {
    router.push({ name: "payment" })
}

function getShippingMethodPrice(method: ShippingMethod) {
    const hasCannabis = cartStore.totalCannabisAmount > 0
    let price = undefined

    if (method.dynamicPriceWith && hasCannabis) {
        for (let grams of Object.keys(method.dynamicPriceWith)) {
            if (cartStore.totalCannabisAmount <= parseInt(grams)) {
                price = method.dynamicPriceWith[grams]
                break
            }
        }
    }

    if (price === undefined) {
        price = hasCannabis ? method.priceWith : method.priceWithout
    }

    if (method.options) {
        for (const option of method.options) {
            if (cartStore.shippingOptions[method.name].includes(option.name)) {
                price += option.price
            }
        }
    }

    return price
}

const availableShippingMethods = computed(() => {
    return appConfig.value.shippingMethods.filter(shippingMethod => {
        // if (cartStore.paymentMethod === "offline") {
        //     // if payment method is offline, only pickup is allowed
        //     return shippingMethod.name === "pickup"
        // }

        return true
    })
})

function selectShippingMethod(method: ShippingMethod) {
    cartStore.shippingMethod = method.name

    router.push({ name: method.name === "pickup" ? "prescription" : "delivery-address" })
}
</script>

<template>
    <div class="container my-5">
        <div class="small text-muted mb-5 pb-3">Home / Warenkorb</div>

        <h2>Übersicht</h2>
        <div class="mb-3">
            <h1>Versand</h1>
        </div>

        <CheckoutTimeline :step="3" />
        <template v-for="method in availableShippingMethods" :key="method.name">
            <button class="wizard-button" @click="selectShippingMethod(method)">
                <div class="d-flex justify-content-between">
                    <b>{{ method.title }}</b>
                    <b>{{ $currency(getShippingMethodPrice(method)) }}</b>
                </div>
                <div class="small">{{ method.subtitle }}</div>
            </button>
        </template>
        <div class="mt-3 ml-3">
            * Originalrezept muss in der Apotheke vorliegen und die Zahlung zur Rechnung auf dem Konto der Apotheke
            eingegangen sein.
        </div>
    </div>
</template>
