<script setup lang="ts">
import { assetLink } from "@/common/util"
import type { Article } from "@/model/backend/article"
import { useShopAppConfig } from "@/shop/composables"
import { useAuthentication } from "@/shop/composables/useAuthentication"
import { useRouter } from "vue-router"
/*─────────────────────────────────────┐
│   functions                          │
└─────────────────────────────────────*/
async function loginAndGoToArticle(article: Article) {
    if (await auth.successfulLogin()) {
        router.push({ name: "article", params: { urlId: article.urlId, slug: article.slug } })
    }
}
/*─────────────────────────────────────┐
│   composables                        │
└─────────────────────────────────────*/
const { appConfig } = useShopAppConfig()
const auth = useAuthentication()
const router = useRouter()
/*─────────────────────────────────────┐
│   setup                              │
└─────────────────────────────────────*/
</script>

<template>
    <div class="container py-md-5">
        <div class="teaser">
            <div class="teaser-text">
                <h2>CANN24</h2>
                <h1>Medizinisches Cannabis</h1>

                <p>
                    Moin und Herzlich Willkommen bei CANN24! Wir sind Deine Apotheke für medizinisches Cannabis und
                    versorgen Dich deutschlandweit per Versand und natürlich auch direkt vor Ort in Hamburg-Wilhelmsburg
                    in der apotheke am inselpark.
                </p>

                <RouterLink class="btn btn-primary" :to="{ name: 'articles' }">Zum Sortiment</RouterLink>
            </div>

            <div class="teaser-image">
                <img src="./start01.jpg" alt="CANN24" class="img-fluid" />
            </div>
        </div>

        <div v-if="appConfig.startPageFeatured" class="teaser pt-md-5">
            <div class="teaser-image">
                <img class="img-fluid" :src="assetLink('/uploads/media/' + appConfig.startPageFeatured.imageName)" />
            </div>

            <div class="teaser-text">
                <h2>{{ appConfig.startPageFeatured.subtitle }}</h2>
                <h1>{{ appConfig.startPageFeatured.title }}</h1>

                <div v-html="appConfig.startPageFeatured.text"></div>

                <RouterLink
                    v-if="appConfig.startPageFeatured.visible"
                    class="btn btn-primary"
                    :to="{
                        name: 'article',
                        params: {
                            urlId: appConfig.startPageFeatured.article.urlId,
                            slug: appConfig.startPageFeatured.article.slug,
                        },
                    }"
                >
                    Zum Artikel
                </RouterLink>

                <button
                    v-if="!appConfig.startPageFeatured.visible"
                    class="btn btn-primary"
                    @click="loginAndGoToArticle(appConfig.startPageFeatured.article)"
                >
                    Zum Artikel
                </button>
            </div>
        </div>
    </div>

    <div v-if="appConfig.startPageArticles?.length" class="container-fluid bg-teaser-secondary">
        <div class="container">
            <div class="teaser teaser-secondary">
                <div class="teaser-text pr-md-3">
                    <h1>Sortiment</h1>

                    <p>
                        Auszug aus unserem Sortiment. Alle Verfügbarkeiten findest Du
                        <RouterLink :to="{ name: 'articles' }">hier</RouterLink>.
                    </p>

                    <RouterLink class="btn btn-primary" :to="{ name: 'articles' }">Zum Sortiment</RouterLink>
                </div>

                <div class="teaser-image" style="order: 3 !important">
                    <div class="product-teaser-cards">
                        <RouterLink
                            v-for="article in appConfig.startPageArticles"
                            :key="article.id"
                            class="product-teaser-card"
                            :to="{ name: 'article', params: { urlId: article.urlId, slug: article.slug } }"
                        >
                            <img
                                v-if="article.brand?.logoName"
                                :src="assetLink(`/uploads/media/${article.brand?.logoName}`)"
                                :alt="article.brand?.name"
                            />
                            <div class="product-teaser-card-name">
                                {{ article.publicNameSecondary }}
                            </div>
                            <div class="product-teaser-card-details">
                                {{ article.species }} &middot;
                                <span class="text-nowrap">THC {{ article.thc }} %</span>
                            </div>
                            <div class="product-teaser-card-hover">
                                <button class="btn btn-primary">Zum Produkt</button>
                            </div>
                        </RouterLink>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="teaser py-md-5 py-4 my-md-5">
            <div class="teaser-image">
                <img src="./start02.jpg" alt="CANN24" class="img-fluid" />
            </div>

            <div class="teaser-text">
                <h2>Wissenswertes</h2>
                <h1>Cannabis als Arzneimittel</h1>

                <p>
                    Dich interessiert die Vielseitigkeit von Cannabis als Arzneimittel, die Geschichte und Entwicklung
                    in der medizinischen und kulturellen Verwendung? Das freut uns besonders, denn uns geht es genauso.
                    In unserer Rubrik Wissenswertes berichten wir regelmäßig über unterschiedliche Themengebiete. Als
                    alter Hase sind für Dich vielleicht Neuentwicklungen aus der Forschung interessant, als noch
                    unerfahrener Patient freust Du Dich vielleicht über etwas Grundwissen. Wir hoffen, dass für jeden
                    etwas Interessantes dabei ist, jedoch sind einige Inhalte nur registrierten Patienten vorbehalten.
                    Wenn Du also noch nicht registriert bist, dann werde jetzt Teil der CANN24-Community.
                </p>

                <!--                <a route-href="worth-knowing" class="btn btn-primary">Mehr erfahren</a>-->
                <div class="btn btn-primary">kommt demnächst</div>
            </div>
        </div>

        <!--        <div class="teaser">-->
        <!--            <div class="teaser-text">-->
        <!--                <h2>Bei jeder Bestellung</h2>-->
        <!--                <h1>Mach’s Premium – Ein Upgrade, das sich lohnt</h1>-->

        <!--                <p>-->
        <!--                    Du möchtest Deine Arznei bestmöglich lagern? Dann bestell sie Dir bei uns in unserem-->
        <!--                    Premiumglasgefäß aus Miron-Glas. Zu jedem Glas erhältst du ein Boveda-Pack, damit Du Deine Arznei-->
        <!--                    bei idealer Luftfeuchtigkeit lagern kannst und sie somit vor Austrocknung und Schimmelbefall-->
        <!--                    schützt. Verwende das Glas anschließend wieder und wieder und tu damit etwas für die Umwelt. Wir-->
        <!--                    freuen uns, wenn wir den Plastikverbrauch damit ein klein wenig senken können.-->
        <!--                </p>-->

        <!--                <a route-href="community" class="btn btn-primary">Mehr erfahren</a>-->
        <!--            </div>-->

        <!--            <div class="teaser-image">-->
        <!--                <img src="./start03.jpg" alt="CANN24" class="img-fluid">-->
        <!--            </div>-->
        <!--        </div>-->
    </div>
</template>
