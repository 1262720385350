<template>
    <div class="container text-justify">
        <div class="breadcrumb">
            <RouterLink class="breadcrumb-item" :to="{ name: 'home' }">Home</RouterLink>
            <div class="breadcrumb-item">Impressum</div>
        </div>

        <h1 class="my-5">Impressum</h1>

        <p>
            apotheke am inselpark e.K.
            <br />
            Neuenfelder Straße 31
            <br />
            21109 Hamburg
        </p>
        <br />
        <p>
            Tel.: 040.30 23 86 990
            <br />
            Fax: 040.30 23 86 99 20
        </p>
        <br />
        <p>
            E-Mail:
            <a href="mailto:info@apotheke-inselpark.de">info@apotheke-inselpark.de</a>
        </p>
        <p>
            Inhaber: Apotheker Björn Schrage e.K.
            <br />
            Verantwortlich für den Inhalt nach § 55 RStV und § 5 TMG: Apotheker Björn Schrage e.K.
        </p>
        <p>
            Registergericht: Amtsgericht Hamburg
            <br />
            Registernummer: HRA 116856
            <br />
            Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz: DE 274975656
            <br />
            Zuständige Behörde: Behörde für Justiz und Verbraucherschutz
            <br />
            Zuständige Kammer: Apothekerkammer Hamburg
            <br />
            Gesetzliche Berufsbezeichnung: Apotheker, erworben in Hamburg/Deutschland
        </p>

        <h3 class="mt-5">Allgemeiner Disclaimer</h3>
        <p>
            Für Vollständigkeit, Fehler redaktioneller und technischer Art, Auslassungen usw. sowie die Richtigkeit der
            Eintragungen kann keine Haftung übernommen werden. Insbesondere kann keine Gewähr für die Vollständigkeit
            und Richtigkeit von Informationen übernommen werden, die über weiterführende Links erreicht werden. Anbieter
            sind für die eigenen Inhalte, die sie zur Nutzung bereithalten, nach den allgemeinen Gesetzen
            verantwortlich. Von diesen eigenen Inhalten sind Querverweise auf die von anderen Anbietern bereitgehaltenen
            Inhalte zu unterscheiden. Die Verweise durch Hyperlinks auf Inhalte fremder Webseiten dienen lediglich der
            Information. Die Verantwortlichkeit für diese fremden Inhalte liegt alleine bei dem Anbieter, der die
            Inhalte bereithält. Vor dem Einrichten des entsprechenden Verweises sind die Websites der anderen Anbieter
            mit großer Sorgfalt und nach bestem Wissen und Gewissen überprüft worden. Es kann jedoch keine Gewähr für
            die Vollständigkeit und Richtigkeit von Informationen übernommen werden, die über weiterführende Hyperlinks
            erreicht werden. Der Inhalt der anderen WebSeiten kann jederzeit ohne unser Wissen geändert werden.
        </p>

        <p>
            Für sämtliche Links auf diesen Seiten gilt: Wir betonen, dass wir keinen Einfluss auf die Gestaltung und die
            Inhalte der durch Link von unserer Homepage aus erreichbaren Seiten anderer Anbieter haben und uns deren
            Inhalt nicht zu eigen machen. Diese Erklärung gilt für alle Seiten dieser Homepage und die dort angebrachten
            Links. Falls wir auf Seiten verweisen, deren Inhalt Anlass zur Beanstandung gibt, bitten wir um Mitteilung.
        </p>
    </div>
</template>
