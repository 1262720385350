// This file is generated by ArticleSubItems class
// Do not edit it manually

export const availablePackingValues = [
    "Doy-Pack",
    "Chubby Gorilla Dose",
    "Glastiegel"
]
export const availableHumidityValues = [
    "Integra Boost 55%",
    "Integra Boost 62%",
    "Kein Integra Boost"
]
export const availableTypes = ["packaging", "humidity"]
export type AvailableTypes = "packaging" | "humidity"

export const availableValuesByType: Record<string, string[]> = {
    "packaging": availablePackingValues,
    "humidity": availableHumidityValues
}

export const defaultPackagingType = "Doy-Pack"
export const defaultHumidityType = "Kein Integra Boost"
export const pricePerGlasJar = 199

export type PACKAGING_TYPE = "Doy-Pack" | "Chubby Gorilla Dose" | "Glastiegel"
export type HUMIDITY_TYPE = "Integra Boost 55%" | "Integra Boost 62%" | "Kein Integra Boost"

export type ARTICLE_SUB_ITEM_VALUE = PACKAGING_TYPE | HUMIDITY_TYPE

export function calculateSubItemPrice(type: ARTICLE_SUB_ITEM_VALUE, articleAmount: number): number {
    switch (type) {
        case "Glastiegel":
            return Math.ceil(articleAmount / 20) * pricePerGlasJar
        default:
            return 0
    }
}

export function validateSubItem(type: string, value: string): boolean {
    if (!availableTypes.includes(type)) {
        return false;
    }
    
    if (!availableValuesByType[type].includes(value)) {
        return false;
    }
    
    return true;
}

export function articleSupportsSubItem(articleType: string, subItemType: AvailableTypes): boolean {
    return articleType === "cannabis"
}
