<script setup lang="ts">
import { useShopDialogs } from "@/shop/composables/useShopDialogs"
import { useHttpClient } from "@/vf"
import { useRouter } from "vue-router"
import { declineRegistration } from "./event-confirmation"

const props = defineProps<{
    token: string
}>()

type ResponseData = {
    eventName: string
    text: string
    error: "not-found" | "expired" | "answered"
    accepted?: boolean
}

const http = useHttpClient()
const dialog = useShopDialogs()
const router = useRouter()

const response = await http.get<ResponseData>(`/event/registration/token/${props.token}`, undefined, {
    validateStatus: status => status === 200 || status === 404,
})
const data = response.data

async function accept() {
    if (!(await dialog.confirm("Anmeldung bestätigen", "Möchtest du wirklich teilnehmen?"))) {
        return
    }

    try {
        await http.post(`/event/registration/token/${props.token}/accept`)
        dialog.alert(
            "Anmeldung bestätigt",
            "Vielen Dank! Wir freuen uns auf dich. Solltest du doch nicht können, melde dich bitte in deinem Profil ab.",
        )
        router.push("/")
    } catch (e) {
        dialog.alert("Fehler", "Die Anmeldung konnte nicht bestätigt werden")
    }
}

async function decline() {
    if (await declineRegistration(props.token)) {
        router.push("/")
    }
}
</script>
<template>
    <div class="container my-5">
        <template v-if="data.error === 'not-found'">
            <div class="alert alert-danger">Der Link ist leider nicht (mehr) gültig</div>
        </template>

        <template v-else-if="data.error === 'expired'">
            <div class="alert alert-danger">Die Anmeldefrist ist leider abgelaufen</div>
        </template>

        <template v-else-if="data.error === 'answered'">
            <h1>{{ data.eventName }}</h1>
            <div>Du hast bereits geantwortet.</div>
            <template v-if="data.accepted">
                <div>Wenn du doch nicht kommen kannst, melde dich bitte rechtzeitig ab.</div>
                <button class="btn btn-primary mt-3" @click="decline">Abmelden</button>
            </template>
        </template>

        <div v-else>
            <h1>Bestätigung</h1>
            <p class="pre-line">{{ data.text }}</p>

            <div class="d-inline-flex flex-column">
                <button class="btn btn-primary my-1" @click="accept">Ja, ich nehme teil</button>

                <button class="btn btn-primary my-1" @click="decline">Nein, ich komme nicht</button>
            </div>
        </div>
    </div>
</template>
