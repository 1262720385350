<script setup lang="ts">
import { VfDialogContainer } from "@/vf"
import { RouterView } from "vue-router"
import OverlayContainer from "./components/OverlayContainer.vue"
import PageFooter from "./components/page-footer/PageFooter.vue"
import PageNavbar from "./components/page-navbar/PageNavbar.vue"
import { useShopAppConfig } from "./composables"
import OfflinePage from "./pages/offline/OfflinePage.vue"
import { useOverlayStore, useRequestUserInfoStore } from "./store"
import EnterShopOverlay from "./components/overlays/EnterShopOverlay.vue"

const { appConfig } = useShopAppConfig()

// initialize the store. this opens the overlay if necessary
useRequestUserInfoStore()

if (appConfig.value.customEnterShopDialog) {
    const overlay = useOverlayStore()
    overlay.open(EnterShopOverlay)
}
</script>

<template>
    <template v-if="appConfig.offline">
        <OfflinePage />
    </template>
    <template v-else>
        <PageNavbar />
        <div class="router-view">
            <RouterView v-slot="{ Component }">
                <!-- <Transition> -->
                <Suspense>
                    <component :is="Component" />
                </Suspense>
                <!-- </Transition> -->
            </RouterView>
        </div>

        <PageFooter />
        <OverlayContainer />
        <VfDialogContainer />
    </template>
</template>
