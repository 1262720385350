<script setup lang="ts">
import type { AddToCartNoticeDialogResult } from "@/shop/composables/useAddToCardNotice"
import type { DialogController } from "@/vf"
import { reactive } from "vue"

defineProps<{
    controller: DialogController<AddToCartNoticeDialogResult, void>
}>()

const options = reactive<AddToCartNoticeDialogResult>({
    dontShowAgain: false,
})
</script>

<template>
    <div>
        <div class="dialog-header">
            <h2>Bitte beachte</h2>
        </div>

        <div class="dialog-body">
            <p>Artikel wurde hingefügt.</p>
            <p>
                Bitte beachte, dass Artikel im Warenkorb nicht reserviert sind! Erst wenn du deine Bestellung
                abgeschlossen hast, sind deine bestellten Artikel 7 Tage für dich reserviert.
            </p>

            <div class="mt-3">
                <label>
                    <input v-model="options.dontShowAgain" type="checkbox" />
                    Hinweis nicht wieder anzeigen
                </label>
            </div>
        </div>

        <div class="dialog-footer">
            <button type="button" class="btn btn-primary" @click="controller.ok(options)">OK</button>
        </div>
    </div>
</template>
