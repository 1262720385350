<script setup lang="ts">
import type { Order } from "@/model/backend/order"
import { useApp } from "@/vf"

defineProps<{ order: Order }>()
const { apiLink } = useApp()
</script>

<template>
    <span v-if="!order.certificateIds || order.certificateIds.length == 0">
        <img src="@/shop/assets/icons/pdf-inactive.png" width="20" />
    </span>
    <span v-else>
        <a
            v-for="cert in order.certificateIds"
            :key="cert[0] + cert[1]"
            class="pl-md-1"
            target="_blank"
            :href="apiLink('shop/my-account/certificate/' + cert[0] + '/' + cert[1])"
        >
            <img src="@/shop/assets/icons/pdf.png" width="20" />
        </a>
    </span>
</template>
