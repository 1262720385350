<template>
    <div class="d-flex align-items-lg-center flex-column flex-lg-row" style="gap: 1rem">
        <a href="https://apps.apple.com/de/app/das-e-rezept/id1511792179" target="_top">
            <img
                src="@/shop/assets/apple-app-store-de.png"
                alt="App Store: E-Rezept für iOS herunterladen"
                height="54"
            />
            <span class="sr-only">Zum <span lang="en">Apple App Store</span></span>
        </a>
        <a href="https://play.google.com/store/apps/details?id=de.gematik.ti.erp.app" target="_top">
            <img
                src="@/shop/assets/google-play-de.png"
                alt="Play Store: E-Rezept App für Android herunterladen"
                height="54"
            />
            <span class="sr-only">Zum <span lang="en">Google Play Store</span></span>
        </a>
        <a href="https://appgallery.huawei.com/#/app/C104463531" target="_top">
            <img
                src="@/shop/assets/huawai-app-gallery-de.png"
                alt="AppGallery: E-Rezept App für Android herunterladen"
                height="54"
            />
            <span class="sr-only">Zum <span lang="en">Huawei AppGallery Store</span></span>
        </a>
    </div>
</template>
