<script setup lang="ts">
import { assetLink } from "@/common/util"
import type { BlogPost } from "@/model/backend/blog-post"
import { $formatDate, useHttpGet } from "@/vf"
import { presetImages } from "@/common/assets/preset-images/preset-images"
/*─────────────────────────────────────┐
│   props                              │
└─────────────────────────────────────*/
const props = defineProps<{
    id: string
}>()

const { data: post } = await useHttpGet<BlogPost>(`/blog/${props.id}`)
</script>

<template>
    <div class="container">
        <div class="breadcrumb">
            <RouterLink class="breadcrumb-item" :to="{ name: 'home' }">Home</RouterLink>
            <RouterLink class="breadcrumb-item" :to="{ name: 'blog' }">Neuigkeiten</RouterLink>
            <div class="breadcrumb-item">{{ post!.title }}</div>
        </div>
    </div>
    <div class="container">
        <div class="blog-post-image">
            <img
                style="max-height: 500px"
                class="img-fluid mx-auto d-block"
                :src="post?.presetImageIndex === undefined ? assetLink('/uploads/media/' + post!.imageName) : presetImages[post.presetImageIndex]"
            />
        </div>
        <div v-if="post!.onlyVisibleForLoggedInUsers" class="bg-primary px-2 py-1 my-2 text-center">
            <h2 class="text-white m-0">&bullet; Community Exklusiv &bullet;</h2>
        </div>
        <!-- @formatter:off -->
        <h2>{{ $formatDate(post!.date!, "dd. LLLL yyyy") }} / {{ post!.categoryName }}</h2>
        <!-- @formatter:on -->
        <h1>{{ post!.title }}</h1>

        <p class="pre-line">
            {{ post!.introText }}
        </p>

        <p v-html="post!.text"></p>
    </div>
</template>
