<script setup lang="ts">
import { ref } from "vue"
defineProps<{
    question?: string
}>()
const open = ref(false)
</script>

<template>
    <div class="faq">
        <div class="faq-q" @click="open = !open">
            <i :class="'fa fa-fw ' + (open ? 'fa-chevron-down' : 'fa-chevron-right')"></i>
            {{ question }}
        </div>
        <div v-show="open" class="faq-a">
            <slot></slot>
        </div>
    </div>
</template>
