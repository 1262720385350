<script setup lang="ts">
import { sleep } from "@/vf"
import { useClipboard } from "@vueuse/core"
import { ref } from "vue"

const props = defineProps<{ value: string }>()

const valueCopied = ref(false)

const clipboard = useClipboard()
async function copyValue() {
    if (!clipboard.isSupported.value) {
        alert("Kopieren wird von deinem Browser nicht unterstützt.")
        return
    }

    clipboard.copy(props.value)
    valueCopied.value = true
    await sleep(1000)
    valueCopied.value = false
}
</script>

<template>
    <div class="copy-input-field">
        <div class="copy-input-field--content">
            {{ value }}
        </div>
        <button title="Addresse kopieren" class="copy-input-field--copy-button" @click="copyValue">
            <i class="fas fa-fw fa-copy"></i>
        </button>
    </div>

    <div style="height: 1em" class="small text-primary">
        {{ valueCopied ? "Adresse kopiert!" : "" }}
    </div>
</template>

<style scoped>
.copy-input-field {
    display: flex;
    border-radius: 10px;
}

.copy-input-field--content {
    padding: 0.5rem 1rem;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    flex-shrink: 1;
    overflow-x: scroll;
}

.copy-input-field--copy-button {
    cursor: pointer;
    flex-shrink: 0;
    border-left: 1px solid #ccc;
    background-color: var(--primary);
    color: #fff;
    border: none;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;

    display: grid;
    place-items: center;

    width: 3rem;
}
</style>
