<script setup lang="ts">
import type { Order } from "@/model/backend/order"
import { useApp } from "@/vf"

defineProps<{ order: Order }>()
const { apiLink } = useApp()
</script>
<template>
    <a v-if="order.reviewed" class="pl-md-1" target="_blank" :href="apiLink(`shop/order/${order.id}/invoice`)">
        <img src="@/shop/assets/icons/pdf-inverted.png" width="20" />
    </a>

    <span v-else>
        <img src="@/shop/assets/icons/pdf-inactive.png" width="20" />
    </span>
</template>
