<script setup lang="ts">
import type { ARTICLE_SUB_ITEM_VALUE } from "@/model/article-sub-items"
import VfDropdown from "@/vf/components/VfDropdown.vue"
import { computed, ref } from "vue"

const props = defineProps<{
    modelValue: any
}>()

const emit = defineEmits<{
    (e: "update:modelValue", value: any): void
}>()

const value = computed({
    get: () => props.modelValue,
    set: (value: string | null) => emit("update:modelValue", value),
})

const showOptions = ref(false)

function select(newValue: ARTICLE_SUB_ITEM_VALUE | null) {
    value.value = newValue
    showOptions.value = false
}
</script>

<template>
    <VfDropdown :apply-container-width-to-menu="true">
        <template #default="{ toggleDropdown }">
            <div class="can-dropdown" @click="toggleDropdown()">
                <div class="can-dropdown--value">{{ value ?? "Bitte wählen" }}</div>
            </div>
        </template>
        <template #menu="{ closeDropdown }">
            <div class="dropdown-menu">
                <slot
                    name="options"
                    v-bind="{ select: (newValue: ARTICLE_SUB_ITEM_VALUE | null) => closeDropdown(select(newValue)), value }"
                ></slot>
            </div>
        </template>
    </VfDropdown>
</template>

<style scoped>
.can-dropdown {
    border: 1px solid var(--primary);
    border-radius: 5px;
    /* position: relative; */
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.can-dropdown--value {
    padding: 0.5rem;
}

.can-dropdown::after {
    content: "\f078"; /* Unicode for fa-chevron-down */
    font-family: var(--fa-style-family, "Font Awesome 6 Free");
    font-weight: 900;
    margin-right: 0.5rem;
}
</style>
