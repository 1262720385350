<script setup lang="ts">
import { useOverlayStore } from "@/shop/store"
import { ref } from "vue"
import { useRouter } from "vue-router"

function close(): void {
    overlayStore.close()
}

function searchFor(str: string) {
    router.push({ name: "articles", query: { search: str } })
    overlayStore.close()
}

const overlayStore = useOverlayStore()
const router = useRouter()
const searchValue = ref("")
</script>

<template>
    <div class="search">
        <button class="overlay-close" @click="close()">
            <i class="fa fa-times"></i>
        </button>

        <div class="search-inner">
            <form @submit.prevent="searchFor(searchValue)">
                <div class="search-field">
                    <label for="search_input" class="sr-only">Suchbegriff eingeben</label>
                    <input
                        id="search_input"
                        v-model="searchValue"
                        type="search"
                        placeholder="Suchbegriff eingeben..."
                    />
                    <i class="fa fa-search" @click="searchFor(searchValue)"></i>
                </div>
            </form>

            <div class="search-heading mt-5 mb-4">Häufige Suchbegriffe</div>

            <div class="search-buttons">
                <button class="btn btn-primary" @click="searchFor('Tilray')">Tilray</button>
                <button class="btn btn-primary" @click="searchFor('Aurora')">Aurora</button>
                <button class="btn btn-primary" @click="searchFor('Indica')">Indica</button>
                <button class="btn btn-primary" @click="searchFor('Sativa')">Sativa</button>
            </div>
        </div>
    </div>
</template>
