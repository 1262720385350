<script lang="ts" setup>
import { deepCopy } from "@/vf"
import { findDataForName, findSchemaForName, getVfFormInject } from "@/vf/utils/SymfonyForm"
import { FormKitSchema } from "@formkit/vue"
import { type Ref, watchEffect } from "vue"
import { inject, onMounted, ref, toRef, watch } from "vue"
import VfFormRendered from "./VfFormRendered.vue"

const props = withDefaults(
    defineProps<{
        name?: string // if no name is supplied, a schema is *required*
        schema?: any // if no schema is supplied, a name is *required*
        path?: string // path in the schema if the form row is inside a FormKit element
        isRest?: boolean
        onlyMarkRendered?: boolean
        sectionsSchema?: any
        data?: any
        disabled?: boolean
        hideWhenMissing?: boolean
        filterOptionsSource?: any
        filterOptionsDisableHidden?: boolean
    }>(),
    {
        filterOptionsDisableHidden: true,
        filterOptionsSource: undefined,
        name: undefined,
        schema: undefined,
        path: undefined,
        sectionsSchema: undefined,
        data: undefined,
    },
)
const element = ref<HTMLSpanElement | null>(null)

const emit = defineEmits<{
    (
        e: "filterOptions",
        data: Ref,
        options: { key: string; value: string; additionalData?: object }[],
        updateOptions: (options: { key: string; value: string; additionalData?: object }[]) => void,
    ): { key: string; value: string }
}>()

const path = props.path ? toRef(props, "path") : inject<Ref<string | undefined>>("vf-form-group", ref(undefined))

// let label: string | null = null

// we *want* to lose reactivity here
// eslint-disable-next-line vue/no-setup-props-destructure
let schema = props.schema
// eslint-disable-next-line vue/no-setup-props-destructure
let data = props.data
const form = getVfFormInject()
if (!schema) {
    if (!props.name) {
        throw new Error("If no schema is provided to VfFormRow, a name is required.")
    }
    try {
        schema = deepCopy(findSchemaForName(props.name, "VfFormRow", path.value))
        data = findDataForName(props.name, "VfFormRow", form)
    } catch (e) {
        if (props.hideWhenMissing) {
            schema = null
            data = null
        } else {
            throw e
        }
    }
}
let showOptions: any

function hotfixVisibleOptions() {
    if (!element.value) return
    element.value.querySelectorAll("li.formkit-option, ul.formkit-options li").forEach((el: Element) => {
        const li = el as HTMLLIElement
        const input = li.querySelector("input") as HTMLInputElement
        const value = input.value
        if (showOptions.find((o: any) => o.value === value)) {
            li.style.display = "block"
        } else {
            li.style.display = "none"
            if (props.filterOptionsDisableHidden) {
                input.checked = false
            }
        }
    })
}

if (schema && schema.options) {
    const options = schema.options
    const varName = "$" + schema.name + "Options"
    schema.options = "$" + varName
    data.value[varName] = options
    showOptions = options
    const updateFilterOptions = () => {
        emit("filterOptions", data, options, newOptions => {
            showOptions = newOptions
            hotfixVisibleOptions()
        })
    }
    if (props.name) {
        watch(() => [findDataForName(props.name!, "VfFormRow", form), props.filterOptionsSource], updateFilterOptions, {
            immediate: true,
        })
    } else {
        updateFilterOptions()
    }
    onMounted(() => hotfixVisibleOptions())
    watch(element, () => hotfixVisibleOptions())
}

if (schema && props.sectionsSchema) {
    // eslint-disable-next-line vue/no-setup-props-destructure
    schema.sectionsSchema = props.sectionsSchema
}

watch(
    () => [props.disabled, props.name, props.schema],
    () => {
        schema.disabled = props.disabled
    },
)
</script>

<template>
    <template v-if="schema">
        <span ref="element">
            <FormKitSchema v-if="!props.onlyMarkRendered" :schema="schema" :data="data" :disabled="disabled" />
        </span>
        <VfFormRendered v-if="!props.isRest" :name="props.name ?? schema.name"></VfFormRendered>
    </template>
</template>
