<script setup lang="ts">
import { useShopAppConfig } from "@/shop/composables"
import { useAuthentication } from "@/shop/composables/useAuthentication"
import { useOverlayStore } from "@/shop/store"
import { useCartStore } from "@/shop/store/useCartStore"
import { useRoute } from "vue-router"
import { MenuOverlay, SearchOverlay } from "../overlays"
import PageNavbarInner from "./PageNavbarInner.vue"
import cartSvgUrl from "./cart.svg"
import searchSvgUrl from "./search.svg"
import userSvgUrl from "./user.svg"

function openSearch(): void {
    overlayStore.open(SearchOverlay)
}

function openMenu(): void {
    overlayStore.open(MenuOverlay)
}

const { appConfig } = useShopAppConfig()
const overlayStore = useOverlayStore()
const auth = useAuthentication()
const route = useRoute()
const cartStore = useCartStore()
</script>

<template>
    <div class="page-navbar">
        <RouterLink class="page-navbar-logo" :to="{ name: 'home' }">
            <img src="@/shop/assets/cann24.svg" style="height: 100%" alt="CANN24" />
        </RouterLink>

        <PageNavbarInner />

        <div class="page-navbar-actions">
            <div class="d-md-none">
                <a v-if="!appConfig.user" href="#" @click="auth.openLogin()">
                    <img :src="userSvgUrl" width="16" height="16" alt="Login" />
                </a>
                <RouterLink
                    v-if="appConfig.user"
                    :to="{ name: 'my-account' }"
                    :class="{ active: route.name === 'my-account' }"
                >
                    <img :src="userSvgUrl" width="16" height="16" alt="Mein Konto" />
                </RouterLink>
            </div>
            <div>
                <a href="#" @click="openSearch()">
                    <img :src="searchSvgUrl" width="16" height="16" alt="Suche" />
                </a>
            </div>
            <div v-if="appConfig.user && appConfig.user.payType !== 'doctor'">
                <RouterLink :to="{ name: 'cart' }">
                    <img :src="cartSvgUrl" width="16" height="16" alt="Warenkorb" />
                    <span
                        class="badge badge-primary text-white rounded-circle"
                        style="width: 1.25rem; height: 1.25rem; display: inline-grid; place-content: center"
                    >
                        {{ cartStore.totalItems }}
                    </span>
                </RouterLink>
            </div>
            <div class="ml-0">
                <button class="page-navbar-hamburger-button" @click="openMenu()">
                    <i class="fa fa-bars"></i>
                </button>
            </div>
        </div>
    </div>
</template>
