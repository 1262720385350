<script setup lang="ts">
import { assetLink } from "@/common/util"
import type { OrderItem } from "@/model/backend/order-item"
import { $currency } from "@/vf"
import { computed } from "vue"

const props = defineProps<{
    item: OrderItem
}>()

const packaging = computed(() => props.item.subItems?.find(i => i.type === "packaging"))
const humidity = computed(() => props.item.subItems?.find(i => i.type === "humidity"))
</script>

<template>
    <template v-if="!item.isSpecialType">
        <div class="row checkout-cart-item align-items-center my-5">
            <div class="col-md-2 d-none d-md-block">
                <div class="pr-4">
                    <img v-if="!item.article?.imageName" src="@/shop/assets/blossom.png" class="img-fluid" />
                    <img v-else class="img-fluid" :src="assetLink('/uploads/media/' + item.article.imageName)" />
                </div>
            </div>
            <div class="col-10 col-md-5">
                <div class="checkout-cart-item-brand">
                    {{ item.article?.publicNamePrimary }}
                </div>
                <div class="checkout-cart-item-name">{{ item.articleName }}</div>
                <div
                    v-if="item.article && (item.article.type === 'essence' || item.article.type === 'cannabis')"
                    class="checkout-cart-item-info"
                >
                    THC {{ item.thc ?? item.article.thc }} % &middot; CBD {{ item.cbd ?? item.article.cbd }} %
                </div>
            </div>

            <div class="col-12 col-md-5">
                <div class="mt-3 mt-md-0 d-flex justify-content-between align-items-end">
                    <div>
                        <div class>
                            <b>Menge</b>
                        </div>

                        <span class="d-inline-block text-center" style="min-width: 40px">
                            {{ item.amount }}
                            {{ item.packageAmount !== 1 ? "x " + item.packageAmount : "" }}
                            {{ item.unit }}
                        </span>
                    </div>
                    <div class="text-right">
                        <div class="article-price">
                            {{ $currency(item.totalPrice) }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div v-if="packaging" class="col-md-6 col-12">
                <b>Packmittel</b>
                <p>{{ packaging?.value }}</p>
            </div>
            <div v-if="humidity" class="col-md-6 col-12">
                <b>Integra</b>
                <p>{{ humidity?.value }}</p>
            </div>
        </div>
        <hr class="my-2" />
    </template>
</template>
