<script setup lang="ts">
import type { DialogController } from "@/vf"
import { computed } from "vue"

const props = defineProps<{
    controller: DialogController<void, { title: string; message: string }>
}>()
const options = computed(() => props.controller.props)
</script>

<template>
    <div>
        <div class="dialog-header">
            <h2>{{ options.title }}</h2>
        </div>

        <div class="dialog-body">
            <p>{{ options.message }}</p>
        </div>

        <div class="dialog-footer">
            <button type="button" class="btn btn-primary" @click="controller.ok()">OK</button>
        </div>
    </div>
</template>
