import "./assets/style/main.scss"

import { createPinia } from "pinia"
import { createApp } from "vue"

import { useAppConfig } from "@/vf"
import { registerAnimateShow } from "@/vf/plugins/animate-show"
import { plugin as formkit } from "@formkit/vue"
import App from "./App.vue"
import { createFormkitConfig } from "./formkit.config"
import router from "./router"

const app = createApp(App)

app.use(createPinia())
app.use(formkit, createFormkitConfig())
registerAnimateShow(app)

useAppConfig()
    .loadInitialAppConfig(router)
    .then(() => {
        app.use(router)
        app.mount("#app")
    })
