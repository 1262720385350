<script setup lang="ts">
// generated from file:///input/au_src/shop/pages/lost-password/lost-password.ts
import { useAuthentication } from "@/shop/composables/useAuthentication"
import { useHttpClient } from "@/vf"
import { useRouteQuery } from "@vueuse/router"
import { ref } from "vue"
/*─────────────────────────────────────┐
│   props                              │
└─────────────────────────────────────*/
const token = useRouteQuery("token")

/*─────────────────────────────────────┐
│   functions                          │
└─────────────────────────────────────*/
function activate(): void {
    if (token.value) {
        page.value = "new-password"
    }
}

async function submitRequest(): Promise<void> {
    try {
        loading.value = true
        await http.post("/request_password", { email: email.value })
        page.value = "request-success"
    } finally {
        loading.value = false
    }
}

async function submitNewPassword(): Promise<void> {
    if (newPassword.value.first !== newPassword.value.second || newPassword.value.first.length < 3) {
        return
    }
    try {
        loading.value = true
        await http.post("/reset_password", {
            token: token.value,
            password: newPassword.value.first,
        })
        page.value = "success"
    } catch (e) {
        page.value = "invalid-token"
    } finally {
        loading.value = false
    }
}

/*─────────────────────────────────────┐
│   composables                        │
└─────────────────────────────────────*/
const http = useHttpClient()
const auth = useAuthentication()
/*─────────────────────────────────────┐
│   refs                               │
└─────────────────────────────────────*/
const email = ref<string>()
const loading = ref<any>(false)
const page = ref<"email" | "new-password" | "request-success" | "success" | "invalid-token">("email")
const newPassword = ref<any>({ first: "", second: "" })
/*─────────────────────────────────────┐
│   setup                              │
└─────────────────────────────────────*/
await activate()
</script>

<!-- generated from file:///input/au_src/shop/pages/lost-password/lost-password.html -->
<template>
    <div class="customer-area-header">
        <div class="container">
            <div class="customer-area-breadcrumb">Home / Passwort vergessen</div>
            <div class="customer-area-heading">
                <h2>CANN24</h2>
                <h1>Passwort vergessen</h1>
            </div>
        </div>
    </div>
    <div class="container">
        <form v-if="page === 'email'" @submit.prevent="submitRequest()">
            <div class="py-5">
                Du hast Dein Passwort vergessen? Kein Problem, gib einfach Deine E-Mailadresse unten ein und wir senden
                Dir einen Link zum Zurücksetzen.
            </div>

            <div class="form-group">
                <label for="lost_password_mail" class="required">E-Mail</label>
                <input id="lost_password_mail" v-model="email" type="email" required class="form-control" />
            </div>

            <button type="submit" class="btn btn-primary my-5" :disabled="loading">Passwort zurücksetzen</button>
        </form>

        <div v-if="page === 'request-success'" class="alert alert-success my-5">
            Vielen Dank. Wir haben Dir eine E-Mail geschickt. Bitte klicke auf den darin enthaltenen Link um ein neues
            Passwort zu vergeben.
        </div>

        <form v-if="page === 'new-password'" @submit.prevent="submitNewPassword()">
            <div class="py-5">Du kannst nun ein neues Passwort vergeben.</div>

            <div class="form-group">
                <label for="new_password_first" class="required">Neues Passwort</label>
                <input
                    id="new_password_first"
                    v-model="newPassword.first"
                    type="password"
                    required
                    class="form-control"
                />
            </div>

            <div class="form-group">
                <label for="new_password_second" class="required">Neues Passwort wiederholen</label>
                <input
                    id="new_password_second"
                    v-model="newPassword.second"
                    type="password"
                    required
                    class="form-control"
                />
            </div>

            <button type="submit" class="btn btn-primary mt-4 mb-2" :disabled="loading">Passwort speichern</button>

            <div v-if="newPassword.first !== newPassword.second" class="mb-5 text-danger">
                Passwörter stimmen nicht überein
            </div>
        </form>

        <div v-if="page === 'invalid-token'" class="alert alert-danger my-5">
            Der aufgerufene Link ist leider nicht mehr gültig.
        </div>

        <div v-if="page === 'success'" class="alert alert-success my-5">
            Dein Passwort wurde erfolgreich geändert. Du kannst Dich nun wieder
            <a href="#" @click="auth.openLogin()">einloggen</a>
            .
        </div>
    </div>
</template>
