<script setup lang="ts"></script>

<template>
    <p>
        Alle angegebenen Werte sind dem Analysenzertifikat dieser Charge entnommen. Ab einem TYMC von 20.000 KBE/g oder
        einem TAMC ab 100.000 KBE/g geben wir keine Freigabe für eine unbedenkliche Inhalation der Wirkstoffe. Eine
        orale Anwendung (Tee-Zubereitung) ist dagegen im Rahmen der Zulassung unbedenklich.
    </p>

    <p>
        KBE = Kolonie bildende Einheiten<br />
        TYMC = Total Yeast & Mold Count (Gesamtzahl an Hefen und Schimmelpilzen)<br />
        TAMC = Total Aerobic Microbial Count (Gesamtzahl aerober Mikroorganismen)
    </p>
</template>
