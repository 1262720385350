import { defineStore } from "pinia"
import { ref } from "vue"

export const useDevMode = defineStore("dev-mode", () => {
    const devMode = ref(false)

    function toggle(state: boolean | undefined) {
        devMode.value = state ?? !devMode.value
    }

    // register commands for the dev console
    ;(window as any).devMode = {
        on: () => toggle(true),
        off: () => toggle(false),
        state: () => devMode.value,
        toggle,
    }

    return { devMode, toggle }
})
