<script setup lang="ts">
import { computed } from "vue"
import { useQuestionId, useWizardStore } from "./wizard-store"

const props = defineProps<{
    id?: string
    label?: string
}>()

const store = useWizardStore()
const questionId = useQuestionId()

// if no explicit id is given, use the label instead
const id = computed(() => {
    const id = props.id
    const label = props.label

    if (typeof id !== "string" && typeof label !== "string") {
        console.error("Please provide an id or label property", id, label)
    }

    return id ?? label!
})

function selectAnswer() {
    console.log("selected answer for ", questionId.value, ":", id.value)
    store.setAnswer(questionId.value, id.value ?? "")
}

function resetAnswer() {
    store.resetAnswer(questionId.value)
}
</script>

<template>
    <template v-if="store.hasAnswered(questionId, id)">
        <div class="wizard-button d-flex align-items-center pl-3" @click="resetAnswer">
            <div>
                <i class="fa fa-fw fa-chevron-left mr-2"></i>
            </div>
            <div>
                <slot name="label">{{ props.label }}</slot>
                <div v-if="$slots.subtitle" class="small"><slot name="subtitle"></slot></div>
            </div>
        </div>
        <slot name="next"></slot>
    </template>

    <template v-if="!store.hasAnsweredQuestion(questionId)">
        <div class="wizard-button" @click="selectAnswer()">
            <slot name="label">{{ props.label }}</slot>
            <div v-if="$slots.subtitle" class="small"><slot name="subtitle"></slot></div>
        </div>
    </template>
</template>
