<script setup lang="ts">
import { useShopAppConfig } from "@/shop/composables"
import { useRequestUserInfoStore } from "@/shop/store"
import type { UserInfos } from "@/shop/store/useRequestUserInfoStore"
import { storeToRefs } from "pinia"
import { reactive } from "vue"

const requestUserInfoStore = useRequestUserInfoStore()
const { appConfig } = useShopAppConfig()

const { requestInfo } = storeToRefs(requestUserInfoStore)

const infos: UserInfos = reactive({
    gender: undefined,
    eventAnswer: undefined,
    hasAcknowledgedCanGDialog: undefined,
})

function close() {
    requestUserInfoStore.dismiss()
}

function save() {
    if (requestInfo.value === "eventAnswer" && [undefined, "open"].includes(infos.eventAnswer)) {
        requestUserInfoStore.dismiss()
        return
    }

    requestUserInfoStore.updateProfile(infos)
}

function acknowledgeCanG() {
    infos.hasAcknowledgedCanGDialog = true
    save()
}
</script>

<template>
    <div class="login">
        <button class="overlay-close login-full" @click="close()"><i class="fa fa-times"></i></button>

        <div class="login-yin login-full" style="max-height: 80vh; overflow-y: auto">
            <form v-if="requestInfo === 'gender'" class="text-left" @submit.prevent="save()">
                <div class="login-heading">Ergänze Dein Profil</div>
                Bitte vervollständige Dein Profil um fortzufahren:

                <div class="form-group my-5">
                    <label for="user_info_gender">Geschlecht:</label>
                    <select id="user_info_gender" v-model="infos.gender" class="custom-select">
                        <option :value="undefined"></option>
                        <option value="m">männlich</option>
                        <option value="f">weiblich</option>
                        <option value="d">divers</option>
                    </select>
                </div>

                <button type="submit" class="btn btn-outline-primary btn-block" :disabled="infos.gender === undefined">
                    Speichern
                </button>
            </form>

            <form v-if="requestInfo === 'eventAnswer'" class="text-left" @submit.prevent="save()">
                <div class="login-heading">Ergänze Dein Profil</div>
                <p>Moin {{ appConfig.user.firstName }}!</p>

                <p>
                    Es ist Zeit gekommen Danke zu sagen. Danke für deine Treue, deine Kritik und Anregungen, die uns
                    geholfen haben uns weiter zu verbessern und natürlich Danke dafür, dass du einen Teil zum Erfolg von
                    CANN24 beiträgst. Deshalb möchten wir mit dir feiern und möchten dich zu einem exklusiven Event über
                    den Dächern von Hamburg einladen.
                </p>

                <p>
                    Unsere Patienten-Party findet unter dem Motto CANN24 x Schall & Rauch am Samstag, den 26.11.22 im
                    Jupiter (Mönckebergstraße 2) direkt gegenüber vom Hamburger Hauptbahnhof statt. Einlass ist ab 15:30
                    und nur mit Gästelistenplatz möglich.
                </p>

                <p>
                    Du möchtest mit uns feiern? Dann zögere nicht und sei dabei. Die Anzahl der Teilnehmer ist begrenzt,
                    also warte nicht zu lange mit deiner Zusage. Begleitpersonen sind aufgrund der limitierten Plätze
                    generell nur in geringem Umfang möglich. Sag erst einmal selbst zu und schick uns den Wunsch einer
                    Begleitperson mit Angabe des vollständigen Namens per Mail. Wenn noch Kapazitäten vorhanden sind,
                    bestätigen wir dir das.
                </p>

                <p>
                    Dich erwartet ein hoffentlich reger Austausch mit anderen Patienten, Cannabisherstellern, dem Team
                    von CANN24 und Künstlern sowie ein exklusives Geheimkonzert und anschließendem DJ-Set.
                </p>

                <p>Für das kulinarische Wohl ist selbstverständlich ebenfalls gesorgt.</p>

                <p>
                    Die Anwendung deiner Arzneimittel ist im gesamten Bereich der Veranstaltungsfläche erlaubt. Bitte
                    denk jedoch daran, deine Legitimation mittels Rezept und Ausweis am Einlass ggf. bestätigen zu
                    können.
                </p>
                <p>
                    Damit sich alle so wohlfühlen können, wie wir es uns wünschen, gilt unter unseren Gästen eine
                    No-Photo-Policy. Damit wollen wir erreichen, dass jeder Gast keine Sorge haben muss auf Bildern
                    anderer Gäste ungewollt zu erscheinen.
                </p>

                <p>Wir freuen uns auf dich.</p>

                <p>
                    Beste Grüße aus Hamburg,
                    <br />
                    dein CANN24-Team
                </p>

                <div class="form-group my-5">
                    <label for="user_info_eventAnswer">Antwort:</label>
                    <select id="user_info_eventAnswer" v-model="infos.eventAnswer" class="custom-select">
                        <option value="open">
                            Ich weiß es noch nicht. Bitte beim nächsten Log-In erneut abfragen.
                        </option>
                        <option value="yes">Ich bin dabei</option>
                        <option value="no">Ich kann leider nicht kommen</option>
                    </select>
                </div>

                <button type="submit" class="btn btn-outline-primary btn-block">Speichern</button>
            </form>

            <div v-if="requestInfo === 'acknowledgeCanG'" class="text-left">
                <p>Liebe Patienten und Kunden der CANN24,</p>

                <p>
                    seit dem 01.04.24 sind Cannabisblüten, Cannabisextrakte und deren Zubereitungen keine
                    Betäubungsmittel mehr und werden daher auch nicht mehr auf Betäubungsmittelrezepten verordnet.
                    Außerdem wurde die Möglichkeit geschaffen, entsprechende Rezepte auch digitalisiert zu übertragen.
                    Im Bestellprozess könnt ihr daher auswählen, auf welchem Wege uns die Verordnung erreichen wird.
                    Papierrezepte können wir auch weiterhin nur im Original einlösen. Zur Einlösung von E-Rezepten
                    stehen unterschiedliche Wege zur Verfügung. Digitale Verordnungen von telemedizinischen Diensten
                    akzeptieren wir dagegen ausschließlich per Direktabruf durch uns auf den Servern des
                    telemedizinischen Anbieters. Der dazu erforderliche Link muss uns bereitgestellt werden.
                </p>

                <p>
                    Wichtig: Wir akzeptieren keine Verordnungen, die nicht die Nennung des gewünschten Produktes
                    enthalten. Zur gesetzlich vorgeschriebenen Überprüfung der Plausibilität der Rezeptur ist es
                    zwingend erforderlich, dass die gewünschte Produktbezeichnung auf dem Rezept genannt wird.
                    Verordnungen wie z.B. „Cannabisblüten THC-Gehalt 20-25% 30g“ erfüllen nicht die gesetzlichen
                    Voraussetzungen und können nicht akzeptiert werden. Außerdem ist auf der Verordnung die Dosierung
                    und Art der Anwendung zu benennen. Eine Diagnose gehört dagegen nicht auf das Rezept.
                </p>

                <div class="mt-5">
                    <button type="button" class="btn btn-primary" @click="acknowledgeCanG()">
                        Habe ich verstanden
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
