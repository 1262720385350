<script setup lang="ts">
import type { Order } from "@/model/backend/order"
import { $shortdatetime, useApp, useHttpClient } from "@/vf"
import type { AxiosError } from "axios"
import { ref } from "vue"
/*─────────────────────────────────────┐
│   props                              │
└─────────────────────────────────────*/
const props = withDefaults(
    defineProps<{
        orderHash?: string
    }>(),
    {
        orderHash: undefined,
    },
)
/*─────────────────────────────────────┐
│   functions                          │
└─────────────────────────────────────*/
async function checkId() {
    try {
        const response = await http.post<Order>("check-id", {
            customerNumber: customerNumber.value,
            customerZip: customerZip.value,
            orderHash: props.orderHash,
        })
        responseCode.value = response.status
        order.value = response.data
    } catch (err) {
        const e = err as AxiosError
        responseCode.value = e.response?.status
        console.log(e, responseCode.value)
    }
}
/*─────────────────────────────────────┐
│   composables                        │
└─────────────────────────────────────*/
const app = useApp()
const http = useHttpClient()
/*─────────────────────────────────────┐
│   refs                               │
└─────────────────────────────────────*/
const customerNumber = ref<string>()
const customerZip = ref<string>()
const order = ref<Order>()
const responseCode = ref<number>()
</script>

<template>
    <div class="container my-5">
        <h2>CANN24</h2>
        <h1>Identifikationsnummer prüfen</h1>

        <div v-if="!orderHash" class="alert alert-info">
            Um eine Charge zu überprüfen scanne bitte den QR Code auf der Verpackung.
        </div>

        <div v-if="orderHash">
            <form @submit.prevent="checkId()">
                <div class="row mt-5">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="customerNumber">Patienten ID</label>
                            <input
                                id="customerNumber"
                                v-model="customerNumber"
                                type="text"
                                class="form-control"
                                placeholder="Patienten ID"
                                required
                                minlength="8"
                                maxlength="8"
                            />
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="customerZip">Postleitzahl</label>
                            <input
                                id="customerZip"
                                v-model="customerZip"
                                type="text"
                                class="form-control"
                                placeholder="Postleitzahl"
                                required
                            />
                        </div>
                    </div>
                </div>
                <div class="text-right mb-4">
                    <button type="submit" class="btn btn-primary">Prüfen</button>
                </div>
            </form>

            <div v-if="responseCode == 400" class="alert alert-danger">Bitte gib alle Daten vollständig an.</div>

            <div v-if="responseCode == 404" class="alert alert-danger">
                Die eingegebene Kombination aus Identifikationsnummer und Postleitzahl wurde nicht gefunden oder passt
                nicht zur gescannten Charge.
            </div>

            <div v-if="responseCode == 200 && order">
                <h2>Bestellinformationen</h2>

                <p>
                    <b>Bestellnummer:</b>
                    {{ order.orderNumber }}
                    <br />

                    <b>Datum:</b>
                    {{ $shortdatetime(order.createdAt) }}
                    <br />

                    <b>Kunde:</b>
                    {{ order.invoiceLastName }}, {{ order.invoiceFirstName }}
                </p>

                <table class="table table-striped">
                    <tr>
                        <th class="w-50">Sorte</th>
                        <th class="w-25">Menge (g/ml)</th>
                        <th class="w-25">Zertifikat</th>
                    </tr>
                    <tr v-for="item of order.cannabisItems" :key="item.id!">
                        <td>{{ item.articleName }}</td>
                        <td>{{ item.amount }}</td>
                        <td>
                            <div v-for="(batch, $index) in item.batches" :key="batch.id!">
                                <a
                                    v-if="batch.hasBatchCertificate"
                                    target="_blank"
                                    :href="
                                        app.apiLink(
                                            'shop/check-id/certificate/' +
                                                customerNumber +
                                                '/' +
                                                customerZip +
                                                '/' +
                                                item.id +
                                                '/' +
                                                batch.id,
                                        )
                                    "
                                >
                                    Zertifikat {{ $index > 0 ? $index + 1 : "" }} download
                                </a>
                                <span v-else class="small text-muted">Kein Chargenzertifikat vorhanden</span>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>
