<script setup lang="ts">
import { assetLink } from "@/common/util"
import type { BlogPost } from "@/model/backend/blog-post"
import { $formatDate, useHttpClient } from "@/vf"
import { ref } from "vue"
import { presetImages } from "@/common/assets/preset-images/preset-images"
/*─────────────────────────────────────┐
│   props                              │
└─────────────────────────────────────*/
/*─────────────────────────────────────┐
│   functions                          │
└─────────────────────────────────────*/
async function loadMore() {
    const more = (await http.get<BlogPost[]>("/blog", { offset: posts.value.length, perPage: perPage.value })).data
    posts.value.push(...more)
    if (more.length < perPage.value) {
        hasReachedEnd.value = true
    }
}

/*─────────────────────────────────────┐
│   composables                        │
└─────────────────────────────────────*/
const http = useHttpClient()
/*─────────────────────────────────────┐
│   refs                               │
└─────────────────────────────────────*/
const posts = ref<BlogPost[]>([])
const hasReachedEnd = ref(false)
const perPage = ref(5)
/*─────────────────────────────────────┐
│   setup                              │
└─────────────────────────────────────*/
await loadMore()
</script>

<template>
    <div class="container">
        <div class="breadcrumb">
            <RouterLink class="breadcrumb-item" :to="{ name: 'home' }">Home</RouterLink>
            <div class="breadcrumb-item">Neuigkeiten</div>
        </div>
    </div>
    <div v-for="(post, index) in posts" :key="post.id" :class="index % 2 ? 'teaser-odd' : 'teaser-even'">
        <div class="container py-md-5">
            <div class="teaser">
                <div class="teaser-text">
                    <div
                        v-if="post.onlyVisibleForLoggedInUsers"
                        class="d-inline-block bg-primary px-4 py-1 mt-2 mb-3 text-center w-auto"
                    >
                        <h2 class="text-white m-0 d-inline-block w-auto">&bullet; Community Exklusiv &bullet;</h2>
                    </div>
                    <!-- @formatter:off -->
                    <h2>{{ $formatDate(post.date!, "dd. LLLL yyyy") }} / {{ post.categoryName }}</h2>
                    <!-- @formatter:on -->
                    <h1>{{ post.title }}</h1>

                    <p class="pre-line">
                        {{ post.introText }}
                    </p>

                    <RouterLink class="btn btn-primary" :to="{ name: 'blog-post', params: { id: post.id } }">
                        Zum Artikel
                    </RouterLink>
                </div>
                <div class="teaser-image">
                    <img
                        style="max-height: 500px"
                        class="img-fluid mx-auto d-block"
                        :src="post?.presetImageIndex === undefined ? assetLink('/uploads/media/' + post!.imageName) : presetImages[post.presetImageIndex]"
                    />
                </div>
            </div>
        </div>
    </div>

    <div v-if="!hasReachedEnd" class="py-5 text-center">
        <button type="button" class="btn btn-primary" @click="loadMore()">Mehr laden</button>
    </div>
</template>
