import { createRouter, createWebHistory, type RouteLocationNormalized } from "vue-router"
import { useShopAppConfig } from "../composables"
import { useAuthentication } from "../composables/useAuthentication"
import AboutUsPage from "../pages/about-us/AboutUsPage.vue"
import ArticlePage from "../pages/article/ArticlePage.vue"
import ArticlesPage from "../pages/articles/ArticlesPage.vue"
import BlogPage from "../pages/blog/BlogPage.vue"
import BlogPostPage from "../pages/blog/BlogPostPage.vue"
import CartPage from "../pages/cart/CartPage.vue"
import CheckoutCheckoutPage from "../pages/checkout/CheckoutCheckoutPage.vue"
import CheckoutConfirmationPage from "../pages/checkout/CheckoutConfirmationPage.vue"
import CheckoutDeliveryAddressPage from "../pages/checkout/CheckoutDeliveryAddressPage.vue"
import CheckoutDeliveryMethodPage from "../pages/checkout/CheckoutDeliveryMethodPage.vue"
import CheckoutPaymentPage from "../pages/checkout/CheckoutPaymentPage.vue"
import CheckoutPrescriptionPage from "../pages/checkout/CheckoutPrescriptionPage.vue"
import ContactPage from "../pages/contact/ContactPage.vue"
import FaqPage from "../pages/faq/FaqPage.vue"
import HomePage from "../pages/home/HomePage.vue"
import IdCheckPage from "../pages/id-check/IdCheckPage.vue"
import DataPrivacyPage from "../pages/legal/DataPrivacyPage.vue"
import ImprintPage from "../pages/legal/ImprintPage.vue"
import RevocationPage from "../pages/legal/RevocationPage.vue"
import TocPage from "../pages/legal/TocPage.vue"
import LostPasswordPage from "../pages/lost-password/LostPasswordPage.vue"
import MyAccountPage from "../pages/my-account/MyAccountPage.vue"
import RegistrationPage from "../pages/registration/RegistrationPage.vue"
import EventConfirmationPage from "../pages/event/EventConfirmationPage.vue"

function findArticleByParams(params: { id?: string; urlId?: string }) {
    const { appConfig } = useShopAppConfig()
    return appConfig?.value?.articles?.find(i => (params.id ? i.id == params.id : i.urlId == params.urlId))
}

const router = createRouter({
    history: createWebHistory(import.meta.env.DEV ? "/shop" : "/"),
    scrollBehavior(_to, _from, savedPosition) {
        // when navigating from one checkout page to another, keep the scroll position
        // (scrolling is done in the step itself)
        if (_to.path.startsWith("/checkout") && _from.path.startsWith("/checkout")) {
            return {}
        }

        return savedPosition || { top: 0, behavior: "smooth" }
    },
    routes: [
        {
            path: "/",
            name: "home",
            component: HomePage,
            meta: { title: "Startseite" },
            props: true,
        },
        {
            path: "/sortiment",
            name: "articles",
            component: ArticlesPage,
            meta: { title: "Sortiment" },
            props: true,
        },
        {
            path: "/sortiment/:slug/:urlId",
            name: "article",
            component: ArticlePage,
            meta: {
                title: (to: RouteLocationNormalized) => findArticleByParams(to.params)?.name ?? "Artikel",
            },
            props: true,
            beforeEnter: async to => {
                if (!findArticleByParams(to.params)) {
                    console.warn(`Article not found: ${to.params.id}`)
                    return { name: "articles" }
                }
            },
        },
        {
            path: "/sortiment/:id",
            name: "article-id",
            component: ArticlePage,
            meta: { title: "Artikel" },
            props: true,
            beforeEnter: async to => {
                const { appConfig } = useShopAppConfig()
                const mainArticle = appConfig?.value?.articles?.find(i => i.id == to.params.id)

                if (!mainArticle) {
                    console.warn(`Article not found: ${to.params.id}`)
                    return { name: "articles" }
                }
            },
        },
        {
            path: "/ueber-uns",
            name: "about-us",
            component: AboutUsPage,
            meta: { title: "Über uns" },
        },
        {
            path: "/kontakt",
            name: "contact",
            component: ContactPage,
            meta: { title: "Kontakt" },
        },
        // {
        //     path: "/community",
        //     name: "community",
        //     component: PLATFORM.moduleName("pages/community/community"),
        //     meta: { title: "Community" },
        // },
        // {
        //     path: "/wissenswertes",
        //     name: "worth-knowing",
        //     component: PLATFORM.moduleName("pages/worth-knowing/worth-knowing"),
        //     meta: { title: "Wissenswertes" },
        // },
        {
            path: "/cart",
            name: "cart",
            component: CartPage,
            meta: { title: "Warenkorb", requiresAuth: true },
        },
        {
            path: "/checkout/prescription",
            name: "prescription",
            component: CheckoutPrescriptionPage,
            meta: { title: "Rezeptupload", requiresAuth: true },
        },
        {
            path: "/checkout/payment",
            name: "payment",
            component: CheckoutPaymentPage,
            meta: { title: "Zahlungsart", requiresAuth: true },
        },
        {
            path: "/checkout/delivery",
            name: "delivery",
            component: CheckoutDeliveryMethodPage,
            meta: { title: "Lieferung", requiresAuth: true },
        },
        {
            path: "/checkout/delivery-address",
            name: "delivery-address",
            component: CheckoutDeliveryAddressPage,
            meta: { title: "Adresse", requiresAuth: true },
        },
        {
            path: "/checkout/submit",
            name: "checkout",
            component: CheckoutCheckoutPage,
            meta: { title: "Kasse", requiresAuth: true },
        },
        {
            path: "/checkout/confirmation",
            name: "confirmation",
            component: CheckoutConfirmationPage,
            meta: { title: "Bestätigung", requiresAuth: true },
        },
        {
            path: "/confirmation/:order",
            name: "confirmation-order",
            component: CheckoutConfirmationPage,
            meta: { title: "Bestätigung", requiresAuth: true },
            props: true,
        },
        {
            path: "/registration",
            name: "registration",
            component: RegistrationPage,
            meta: { title: "Registrierung" },
        },
        {
            path: "/passwort-vergessen",
            name: "lost-password",
            component: LostPasswordPage,
            meta: { title: "Passwort vergessen" },
            props: true,
        },
        {
            path: "/my-account",
            name: "my-account",
            component: MyAccountPage,
            meta: { title: "Mein Account", requiresAuth: true },
        },
        {
            path: "/id-check",
            name: "id-check",
            component: IdCheckPage,
            meta: { title: "Identifikationsnummer prüfen" },
        },
        {
            path: "/order-qr/:orderHash",
            name: "order-qr",
            component: IdCheckPage,
            meta: { title: "Identifikationsnummer prüfen" },
            props: true,
        },
        {
            path: "/legal/toc",
            name: "legal-toc",
            component: TocPage,
            meta: { title: "AGB" },
        },
        {
            path: "/legal/imprint",
            name: "legal-imprint",
            component: ImprintPage,
            meta: { title: "Impressum" },
        },
        {
            path: "/legal/data-privacy",
            name: "legal-data-privacy",
            component: DataPrivacyPage,
            meta: { title: "Datenschutzerklärung" },
        },
        {
            path: "/legal/revocation",
            name: "legal-revocation",
            component: RevocationPage,
            meta: { title: "Widerrufsbelehrung" },
        },
        {
            path: "/faq",
            name: "faq",
            component: FaqPage,
            meta: { title: "FAQ" },
        },
        {
            path: "/blog",
            name: "blog",
            component: BlogPage,
            meta: { title: "Neuigkeiten" },
        },
        {
            path: "/blog-post/:id",
            name: "blog-post",
            component: BlogPostPage,
            meta: { title: "Neuigkeiten" },
            props: true,
        },
        {
            path: "/event-confirm/:token",
            name: "event-confirm",
            props: true,
            meta: { title: "Veranstaltung bestätigen" },
            component: EventConfirmationPage, //() => import("../pages/event/EventConfirmationPage.vue"),
        },
    ],
})

router.beforeEach(to => {
    let title: string | undefined = undefined

    switch (typeof to.meta.title) {
        case "string":
            title = to.meta.title
            break
        case "function":
            title = to.meta.title(to)
            break
        default:
            title = undefined
            console.error(`Invalid title for route "${String(to.name)}"`)
            break
    }

    if (title) {
        document.title = title + " • CANN24"
        return
    }

    document.title = "CANN24"
})

router.beforeEach(async to => {
    const auth = useAuthentication()

    if (to.meta.requiresAuth) {
        // if user cancelled authentication, he is already on a new route, which is fine for us
        // if user closed the overlay, we redirect him to the home page
        // if user is authenticated, we let him pass
        if ((await auth.login()) === "closed") {
            return { name: "home" }
        }
    }
})

export default router
