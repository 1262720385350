import { useShopDialogs } from "@/shop/composables/useShopDialogs"
import { useHttpClient } from "@/vf"

export async function declineRegistration(token: string) {
    const http = useHttpClient()
    const dialog = useShopDialogs()

    if (
        !(await dialog.confirm(
            "Abmeldung bestätigen",
            "Möchtest du wirklich absagen? Du kannst dich dann nicht mehr anmelden.",
        ))
    ) {
        return false
    }

    try {
        await http.post(`/event/registration/token/${token}/decline`)
        dialog.alert("Absage bestätigt", "Vielen Dank für deine Rückmeldung")
        return true
    } catch {
        dialog.alert("Fehler", "Die Abmeldung konnte nicht bestätigt werden")
        return false
    }
}
