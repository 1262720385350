<script setup lang="ts">
import type { WizardStore } from "./wizard-store"
import { provideWizardStore } from "./wizard-store"

const props = defineProps<{
    store: WizardStore
}>()

provideWizardStore(props.store)
</script>

<template>
    <slot></slot>
</template>
