import ShopTooManyOrdersWarningDialog from "@/shop/components/dialogs/ShopTooManyOrdersWarningDialog.vue"
import { useDialogs } from "@/vf"
import { defineStore } from "pinia"
import { ref } from "vue"
import { useShopAppConfig } from "../composables"

export const useTooManyOrdersWarningStore = defineStore("tooManyOrdersWarning", () => {
    const alreadyWarned = ref(false)
    const { appConfig } = useShopAppConfig()
    const dialogs = useDialogs()

    async function noWarningOrDoAnyways(): Promise<boolean> {
        if (alreadyWarned.value || !appConfig.value.tooManyOrdersWarning) {
            return Promise.resolve(true)
        }

        alreadyWarned.value = true

        const result = await dialogs.open({
            component: ShopTooManyOrdersWarningDialog,
            props: {},
        })

        return result.output as boolean
    }

    return { alreadyWarned, noWarningOrDoAnyways } as const
})
