<script lang="ts" setup>
import type { Entity, EntityEvent, FormModel, ModuleConfiguration } from "@/vf"
import { generateRandomString } from "@/vf"
import type { FormErrors, VfFormInject } from "@/vf/components/crud/form/types"
import { useHttpClient } from "@/vf/composables/useHttpClient"
import { objectToFormData } from "@/vf/utils/object-to-form-data"
import type { FormKitSchemaNode } from "@formkit/core"
import { setErrors as formkitSetErrors } from "@formkit/core"
import { FormKit } from "@formkit/vue"
import { AxiosError } from "axios"
import { computed, provide, reactive, ref, toRaw, useSlots, watch, type Ref } from "vue"

const emit = defineEmits<{
    (e: "save", event: EntityEvent): void | Promise<void>
    (e: "saved", entity: Entity): void
    (e: "error", errors: FormErrors): void
    (e: "model", entity: Ref<FormModel>): void
}>()

type FormKitSchemaNodeWithName = FormKitSchemaNode & { name: string }

const props = defineProps<{
    config?: ModuleConfiguration
    apiBase?: string
    schema?: FormKitSchemaNodeWithName[]
    schemaRequestParams?: Record<string, any>
    schemaUrl?: string
    loadUrl?: string
    saveUrl?: string
    createUrl?: string
    id?: string
    item?: any
    multiStep?: boolean
}>()

defineExpose({
    setErrors,
    submit,
})

const id = computed(() => {
    if (props.id) {
        return props.id
    }
    if (props.item) {
        return props.item.id
    }

    return null
})

function createSchemaUrl() {
    return apiBase.value + (id.value ? id.value + "/" : "") + "schema"
}

function entityPath() {
    if (props.config) {
        return props.config.entityPath(props.item)
    }
    return apiBase.value + id.value
}

const apiBase = computed(() => {
    let base = props.apiBase
    if (!base && props.config) {
        base = props.config.apiBase()
    }
    return base?.endsWith("/") ? base : base + "/"
})

const schemaUrl = props.schemaUrl ?? createSchemaUrl()
const loadUrl = props.loadUrl ?? entityPath()

let schema: FormKitSchemaNodeWithName[]
let item: Entity
if (props.schema) {
    // eslint-disable-next-line vue/no-setup-props-destructure
    schema = props.schema
} else {
    schema = (await useHttpClient().get(schemaUrl, props.schemaRequestParams)).data as FormKitSchemaNodeWithName[]
}

if (props.item) {
    // eslint-disable-next-line vue/no-setup-props-destructure
    item = props.item
} else if (id.value) {
    item = (await useHttpClient().get(loadUrl)).data as Entity
} else {
    item = {}
}

const formModel = ref<FormModel>(convertEntityToFormModel(item, schema))
emit("model", formModel)
watch(
    formModel,
    () => {
        emit("model", formModel)
    },
    {
        deep: false,
    },
)

console.log("formModel", formModel)
const renderedList = ref([]) // list of all rendered fields

provide("vf-form", {
    schema: ref(schema), // TODO change schema ref into this file
    formModel,
    renderedList,
} as VfFormInject)

function setErrors(data: FormErrors) {
    formkitSetErrors(
        formId,
        data?._, // (optional) An array of form level errors
        data, // (optional) input level errors
    )
    emit("error", data)
}

let submitting = false

function submit() {
    document.getElementById(formId)?.dispatchEvent(new Event("submit"))
}

async function formkitSubmit(newData: any) {
    if (submitting) return
    submitting = true
    // if (schema.value.find(i => i.name == '_token')) {
    //     newData['_token'] = schema.value.find(i => i.name == '_token').data
    // }
    console.log(newData)
    const event = { cancel: false, entity: newData }
    emit("save", event)

    if (event.cancel) {
        submitting = false
        return
    }

    const formData = objectToFormData(newData)
    item.value = newData
    if (id.value) {
        formData.append("_method", "put")
    }
    let newEntity: Entity
    try {
        newEntity = (
            await useHttpClient().post<Entity>(
                props.saveUrl ?? (id.value ? entityPath() : props.createUrl ?? entityPath()),
                formData,
            )
        ).data
    } catch (e) {
        if (e instanceof AxiosError) {
            setErrors(e.response?.data as FormErrors)
        }
        submitting = false
        return
    }
    emit("saved", newEntity)
    submitting = false
}

function convertEntityToFormModel(entity: Entity, schema: any[]): FormModel {
    const formModel: FormModel = {}
    for (const node of schema) {
        if (!node.name && !node.$formkit) continue
        let value = entity?.[node.name]
        let newValue = undefined // prevent proxy
        if (node.$cmp == "TranslationsForm") {
            newValue = []
        }
        if (value !== undefined || node.$formkit == "group") {
            switch (node.$formkit) {
                case "list":
                    newValue = value.map((i: Entity) => convertEntityToFormModel(i, node.children[0].children))
                    break
                case "group":
                    newValue = convertEntityToFormModel(
                        props.multiStep && node.name.startsWith("step") ? item : value, // if a multistep form, get value from the root entry of the item
                        node.children,
                    )
                    break
                case "datetime":
                case "datetime-local":
                    newValue = value.split("+")[0]
                    break
                case "date":
                    newValue = value.split("T")[0]
                    break
                case "checkbox":
                case "select":
                    if (Array.isArray(value)) {
                        newValue = value.map(i => i.id ?? i.toString())
                    }
                    if (typeof value == "object" && "id" in value) {
                        newValue = value.id
                    }
                    break
                case "number":
                    newValue = toRaw(value)
                    if (node.factor) {
                        newValue = newValue / node.factor
                    }
                    break
                default:
                    if (node.$cmp == "VfFormCollection") {
                        newValue = value.map((i: Entity) => convertEntityToFormModel(i, node.children))
                    } else {
                        newValue = toRaw(value) // remove proxy
                    }
            }
        }
        formModel[node.name] = newValue ?? value
    }

    return formModel
}

function generateAdditionalData(schema: any[]) {
    const data: Record<string, any> = {}
    for (const node of schema) {
        if (!node.name && !node.$formkit) continue

        switch (node.$formkit) {
            case "group":
                data[node.name] = generateAdditionalData(node.children)
                break
            case "checkbox":
            case "select":
                if (node["additionalData"]) {
                    data[node.name] = node["additionalData"]
                }

                if ("options" in node) {
                    const additionalData = Object.fromEntries(
                        Object.values(node.options as { additionalData: Record<string, any> })
                            .filter((i: any) => i.additionalData)
                            .map(({ value, additionalData }) => [value, additionalData]),
                    )

                    if (Object.values(additionalData).length > 0) {
                        data[node.name] = additionalData
                    }
                }

                break
        }
    }

    return data
}

const additionalData = reactive<Record<string, any>>(generateAdditionalData(schema))
const formId = generateRandomString()

if (!useSlots().default) {
    console.warn("VfForm: You probably want to implement the default slot of VfForm to render fields")
}
</script>

<template>
    <div>
        <FormKit :id="formId" v-model="formModel" type="form" :actions="false" @submit="formkitSubmit">
            <slot v-bind="{ formModel, schema, additionalData }" />
        </FormKit>
    </div>
</template>