<script setup lang="ts">
import type { Event } from "@/model/backend/event"
import type { EventRegistration } from "@/model/backend/event-registration"
import { $date } from "@/vf"
import { declineRegistration } from "../event/event-confirmation"

const props = defineProps<{
    event: Event
    registration: EventRegistration
}>()

const emit = defineEmits<{
    (e: "reload"): void
}>()

async function decline() {
    await declineRegistration(props.registration.token!)
    emit("reload")
}
</script>
<template>
    <div class="background-field">
        <div>Du hast dich zu einer Veranstaltung angemeldet:</div>
        <h3 class="mt-3 mb-0">{{ props.event.name }}</h3>
        <div class="text-muted">am {{ $date(props.event.date) }}</div>

        <button class="btn btn-primary mt-4" @click="decline">Abmelden</button>
    </div>
</template>
