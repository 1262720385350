<script setup lang="ts">
import { WizardAnswer, WizardNavigate, WizardQuestion, WizardStore } from "@/shop/components/wizard"
import { createWizardStore } from "@/shop/components/wizard/wizard-store"
import { useCartStore, type CartUpload } from "@/shop/store/useCartStore"

const cartStore = useCartStore()
function addUpload(): void {
    uploadInput.click()
}

function removeUpload(index: number): void {
    cartStore.uploads.splice(index, 1)
}

const uploadInput = document.createElement("input")
uploadInput.setAttribute("type", "file")
uploadInput.setAttribute("accept", "image/*,application/pdf")
uploadInput.addEventListener("change", () => {
    if (!uploadInput.files || !uploadInput.files.length) {
        // no files selected
        return
    }
    const blob = uploadInput.files[0]
    const newEntry: CartUpload = {
        file: blob,
        url: undefined,
        selectedAt: new Date(),
    }
    if (uploadInput.files[0].name.endsWith(".png") || uploadInput.files[0].name.endsWith(".jpg")) {
        newEntry.url = window.URL.createObjectURL(blob)
    }
    cartStore.uploads.push(newEntry)
})

const uploadWizardStore = createWizardStore()
</script>

<template>
    <WizardStore :store="uploadWizardStore">
        <WizardQuestion label="Hast du ein Rezept vorliegen?">
            <template #text>
                Du kannst dein Rezept hier hochladen, um den Bearbeitungsprozess zu beschleunigen. Alternativ kannst du
                es auch später in deinem persönlichen Kundenbereich hochladen. Beachte, dass wir erst eine Rezeptprüfung
                und Auftragsbestätigung durchführen können, wenn deine Verordnung für uns einsehbar ist und du auch erst
                dann eine Rechnung in deinem Profil angezeigt bekommst.
            </template>

            <template #options>
                <WizardAnswer label="Ja, ich möchte mein Rezept jetzt hochladen">
                    <template #next>
                        <div class="mt-5">
                            <h3>Jetzt hochladen</h3>

                            <button class="btn btn-primary-filled d-xl-none" @click="addUpload()">
                                Rezept hochladen
                            </button>

                            <div class="row d-none d-xl-flex">
                                <div class="col-md-2">
                                    <div class="border border-primary" style="height: 7rem"></div>
                                </div>
                                <div class="col-md-6 pl-4">
                                    <b class="d-block mb-5">Neues Rezept</b>
                                    <button class="btn btn-primary" @click="addUpload()">Upload</button>
                                </div>
                            </div>
                        </div>
                        <!-- <div v-if="cartStore.uploads.length === 0">
        <div class="py-4">
            <hr />
        </div>
        <div>
            <h3>Später hochladen</h3>

            <p class="mb-2">
                Du kannst Dein Rezept auch später in Deinem persönlichen Kundenbereich hochladen. Beachte, dass wir erst
                eine Rezeptprüfung und Auftragsbestätigung durchführen können, wenn deine Verordnung für uns einsehbar
                ist und du auch erst dann eine Rechnung in deinem Profil angezeigt bekommst.
            </p>

            <RouterLink class="btn btn-primary-filled" :to="{ name: 'checkout' }">Später hochladen</RouterLink>
        </div>
    </div> -->

                        <div
                            v-for="(upload, $index) in cartStore.uploads"
                            :key="$index"
                            class="d-flex justify-content-between row mt-5"
                        >
                            <div class="col-md-2" style="height: 7rem">
                                <div
                                    class="d-flex align-items-center justify-content-center border border-primary h-100"
                                >
                                    <img
                                        v-if="upload.url"
                                        style="max-width: 100%; max-height: 100%; display: block"
                                        :src="upload.url"
                                    />
                                    <img v-else src="@/shop/assets/icons/pdf.png" />
                                </div>
                            </div>
                            <div class="col-md-6 pl-4 d-none d-lg-block">
                                <b class="d-block mb-5">Hochgeladen am</b>
                                {{ upload.selectedAt.toLocaleDateString() }}
                                {{ upload.selectedAt.toLocaleTimeString() }} Uhr
                            </div>
                            <div class="col-md-2 d-flex">
                                <a
                                    class="text-primary mt-auto d-flex justify-content-between align-items-center"
                                    href="javascript:void(0)"
                                    @click="addUpload()"
                                >
                                    <div class="mr-2">
                                        <i class="fas fa-plus"></i>
                                    </div>
                                    <div>Weiteres Rezept hochladen</div>
                                </a>
                            </div>
                            <div class="col-md-2 d-flex">
                                <a
                                    class="text-danger mt-auto d-flex justify-content-between align-items-center"
                                    href="javascript:void(0)"
                                    @click="removeUpload($index)"
                                >
                                    <div class="mr-2">
                                        <i class="fas fa-times"></i>
                                    </div>
                                    <div>Rezept entfernen</div>
                                </a>
                            </div>
                        </div>
                        <!-- <div class="d-flex justify-content-md-between flex-column flex-md-row mt-4 align-items-center"> -->
                        <div v-if="cartStore.uploads.length" class="text-right mt-4">
                            <RouterLink class="btn btn-primary" :to="{ name: 'checkout' }"> Weiter </RouterLink>
                        </div>
                    </template>
                </WizardAnswer>
                <WizardAnswer label="Nein, ich habe gerade kein Rezept zur Hand">
                    <template #next>
                        <WizardNavigate :to="{ name: 'checkout' }" />
                    </template>
                </WizardAnswer>
                <WizardAnswer label="Ich lade mein Rezept später hoch">
                    <template #next>
                        <p>Du kannst dein Rezept jederzeit in deinem Profil hinterlegen.</p>

                        <RouterLink :to="{ name: 'checkout' }" class="btn btn-primary"> Weiter </RouterLink>
                    </template>
                </WizardAnswer>
            </template>
        </WizardQuestion>
    </WizardStore>
</template>
