import ShopAddToCartNotice from "@/shop/components/dialogs/ShopAddToCartNotice.vue"
import { useDialogs } from "@/vf"
import { useLocalStorage } from "@vueuse/core"

export interface AddToCartNoticeDialogResult {
    dontShowAgain: boolean
}

export function useAddToCardNotice() {
    const shouldBeShown = useLocalStorage("showAddToCardNotice", true)
    const dialogs = useDialogs()

    function dontShowAgain() {
        shouldBeShown.value = false
    }

    async function open() {
        if (!shouldBeShown.value) {
            return
        }

        const result = await dialogs.open<AddToCartNoticeDialogResult>({
            component: ShopAddToCartNotice,
            props: {},
        })

        if (result.output?.dontShowAgain) {
            dontShowAgain()
        }
    }

    return {
        open,
        dontShowAgain,
    }
}
