<template>
    <div class="container text-justify">
        <div class="breadcrumb">
            <RouterLink class="breadcrumb-item" :to="{ name: 'home' }">Home</RouterLink>
            <div class="breadcrumb-item">Datenschutzerklärung</div>
        </div>

        <h1 class="my-5">Datenschutzerklärung</h1>

        <p>
            <span><strong>1. Datenschutz auf einen Blick</strong></span>
        </p>
        <p><strong>&nbsp;</strong></p>
        <p><strong>Allgemeine Hinweise</strong></p>
        <p>
            Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten
            passiert, wenn Sie unsere Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich
            identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter
            diesem Text aufgeführten Datenschutzerklärung.
        </p>
        <p>
            <span><strong>&nbsp;</strong></span>
        </p>
        <p>
            <span><strong>Wer ist verantwortlich für die Datenerfassung auf dieser Website?&nbsp;</strong></span>
            Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten können Sie
            dem Impressum dieser Website entnehmen.
        </p>
        <p><strong>&nbsp;</strong></p>
        <p>
            <strong>
                Wie erfassen wir Ihre Daten?
                <br />
            </strong>
            Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z.B. um
            Daten handeln, die Sie in ein Kontaktformular eingeben. Andere Daten werden automatisch beim Besuch der
            Website durch unsere IT-Systeme erfasst. Das sind vor allem technische Daten (z.B. Internetbrowser,
            Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie
            unsere Website betreten.
        </p>
        <p><strong>&nbsp;</strong></p>
        <p>
            <strong>
                Wofür nutzen wir Ihre Daten?
                <br />
            </strong>
            Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten. Andere
            Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden.&nbsp;
        </p>
        <p>
            <span><strong>&nbsp;</strong></span>
        </p>
        <p>
            <span>
                <strong>
                    Welche Rechte haben Sie bezüglich Ihrer Daten?
                    <br />
                </strong>
            </span>
            Sie haben jederzeit das Recht unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer gespeicherten
            personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung, Sperrung oder Löschung
            dieser Daten zu verlangen. Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit
            unter der im Impressum angegebenen Adresse an uns wenden. Des Weiteren steht Ihnen ein Beschwerderecht bei
            der zuständigen Aufsichtsbehörde zu.
        </p>
        <p>
            <span><strong>&nbsp;</strong></span>
        </p>
        <p>
            <span>
                <strong>
                    Analyse-Tools und Tools von Drittanbietern
                    <br />
                </strong>
            </span>
            Beim Besuch unserer Website kann Ihr Surf-Verhalten statistisch ausgewertet werden. Das geschieht vor allem
            mit Cookies und mit sogenannten Analyseprogrammen. Die Analyse Ihres Surf-Verhaltens erfolgt in der Regel
            anonym; das Surf-Verhalten kann nicht zu Ihnen zurückverfolgt werden. Sie können dieser Analyse
            widersprechen oder sie durch die Nichtbenutzung bestimmter Tools verhindern. Detaillierte Informationen dazu
            finden Sie in der folgenden Datenschutzerklärung. Sie können dieser Analyse widersprechen. Über die
            Widerspruchsmöglichkeiten werden wir Sie in dieser Datenschutzerklärung informieren.
        </p>
        <p>
            <span><strong>&nbsp;</strong></span>
        </p>
        <p>
            <span><strong>2. Allgemeine Hinweise und Pflichtinformationen</strong></span>
        </p>
        <p><strong>&nbsp;</strong></p>
        <p>
            <strong>
                Datenschutz
                <br />
            </strong>
            Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre
            personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser
            Datenschutzerklärung. Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben.
            Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende
            Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie
            und zu welchem Zweck das geschieht. Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei
            der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem
            Zugriff durch Dritte ist nicht möglich.
        </p>
        <p><strong>&nbsp;</strong></p>
        <p>
            <strong>
                Hinweis zur verantwortlichen Stelle
                <br />
            </strong>
            Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:
        </p>
        <p><span>&nbsp;</span></p>
        <p>
            <span>
                apotheke am inselpark e.k.
                <br />
            </span>
            Neuenfelder Straße 31
            <br />
            21109 Hamburg
            <br />
            Tel.: &nbsp; 040.30 23 86 990
            <br />
            Fax: &nbsp; 040.30 23 86 99 20
            <br />
            Email: info@apotheke-inselpark.de
            <br />
            Inhaber: Apotheker Björn Schrage e.K.
        </p>
        <p><span>&nbsp;</span></p>
        <p>
            <span>
                Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen
                über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.B. Namen, E-Mail-Adressen o.
                Ä.) entscheidet.
            </span>
        </p>
        <p><strong>&nbsp;</strong></p>
        <p><strong>Hinweis zum Datenschutzbeauftragten</strong></p>
        <p>Name und Anschrift des vom Verantwortlichen ernannten externen Datenschutzbeauftragten ist:</p>
        <p><span>&nbsp;</span></p>
        <p>
            <span>
                A&amp;A Arbeitsschutz GmbH
                <br />
            </span>
            Datenschutz
            <br />
            Kölner Straße 76
            <br />
            41812 Erkelenz
            <br />
            E-Mail:
            <a href="mailto:ds-beauftragter@aa-arbeitsschutz.de">ds-beauftragter@aa-arbeitsschutz.de</a>
        </p>
        <p>
            <span><strong>&nbsp;</strong></span>
        </p>
        <p>
            <span>
                <strong>
                    Widerruf Ihrer Einwilligung zur Datenverarbeitung
                    <br />
                </strong>
            </span>
            Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine
            bereits erteilte Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns.
            Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
        </p>
        <p><strong>&nbsp;</strong></p>
        <p>
            <strong>
                Beschwerderecht bei der zuständigen Aufsichtsbehörde
                <br />
            </strong>
            Im Falle datenschutzrechtlicher Verstöße steht dem Betroffenen ein Beschwerderecht bei der zuständigen
            Aufsichtsbehörde zu. Zuständige Aufsichtsbehörde in datenschutzrechtlichen Fragen ist der
            Landesdatenschutzbeauftragte des Bundeslandes, in dem unser Unternehmen seinen Sitz hat.&nbsp;
        </p>
        <p>&nbsp;</p>
        <p>
            Name und Anschrift des Datenschutzbeauftragten der Hansestadt Hamburg ist:
            <br />
            Der Hamburgische Beauftragte für Datenschutz und Informationsfreiheit (HmbBfDI
            <br />
            Klosterwall 6 (Block C)
            <br />
            Tel.: 040 42854 4040
            <br />
            <a href="mailto:mailbox@datenschutz-hamburg.de">mailbox@datenschutz-hamburg.de</a>
        </p>
        <p><strong>&nbsp;</strong></p>
        <p>
            <strong>
                SSL- bzw. TLS-Verschlüsselung
                <br />
            </strong>
            Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum
            Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-bzw.
            TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers
            von “http://” auf “https://” wechselt und an dem Schloss-Symbol in Ihrer Browserzeile. Wenn die SSL- bzw.
            TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von Dritten
            mitgelesen werden.
        </p>
        <p>
            <span><strong>&nbsp;</strong></span>
        </p>
        <p>
            <span>
                <strong>
                    Auskunft, Sperrung, Löschung
                    <br />
                </strong>
            </span>
            Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten zur Übersendung von nicht
            ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit widersprochen. Die Betreiber der
            Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von
            Werbeinformationen, etwa durch Spam-E-Mails, vor.
        </p>
        <p>
            <span><strong>&nbsp;</strong></span>
        </p>
        <p>
            <span><strong>3. Datenerfassung auf unserer Website</strong></span>
        </p>
        <p>
            <span><strong>&nbsp;</strong></span>
        </p>
        <p>
            <span>
                <strong>
                    Cookies
                    <br />
                </strong>
            </span>
            Die Internetseiten verwenden teilweise so genannte Cookies. Cookies richten auf Ihrem Rechner keinen Schaden
            an und enthalten keine Viren. Cookies dienen dazu, unser Angebot nutzerfreundlicher, effektiver und sicherer
            zu machen. Cookies sind kleine Textdateien, die auf Ihrem Rechner abgelegt werden und die Ihr Browser
            speichert. Die meisten der von uns verwendeten Cookies sind so genannte “Session-Cookies”. Sie werden nach
            Ende Ihres Besuchs automatisch gelöscht. Andere Cookies bleiben auf Ihrem Endgerät gespeichert bis Sie diese
            löschen. Diese Cookies ermöglichen es uns, Ihren Browser beim nächsten Besuch wiederzuerkennen. Sie können
            Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies nur im
            Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie das
            automatische Löschen der Cookies beim Schließen des Browser aktivieren. Bei der Deaktivierung von Cookies
            kann die Funktionalität dieser Website eingeschränkt sein. Cookies, die zur Durchführung des elektronischen
            Kommunikationsvorgangs oder zur Bereitstellung bestimmter, von Ihnen erwünschter Funktionen (z.B.
            Warenkorbfunktion) erforderlich sind, werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert. Der
            Websitebetreiber hat ein berechtigtes Interesse an der Speicherung von Cookies zur technisch fehlerfreien
            und optimierten Bereitstellung seiner Dienste. Soweit andere Cookies (z.B. Cookies zur Analyse Ihres
            Surfverhaltens) gespeichert werden, werden diese in dieser Datenschutzerklärung gesondert behandelt.&nbsp;
        </p>
        <p>
            <span><strong>&nbsp;</strong></span>
        </p>
        <p>
            <span>
                <strong>
                    Server-Log-Dateien
                    <br />
                </strong>
            </span>
            Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log-Dateien,
            die Ihr Browser automatisch an uns übermittelt. Dies sind:
        </p>
        <p><span>&nbsp;</span></p>
        <p>
            <span>
                · Browsertyp und Browserversion
                <br />
            </span>
            · verwendetes Betriebssystem
            <br />
            · Referrer URL
            <br />
            · Hostname des zugreifenden Rechners
            <br />
            · Uhrzeit der Serveranfrage
            <br />
            · IP-Adresse&nbsp;
        </p>
        <p><span>&nbsp;</span></p>
        <p>
            <span>Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen.&nbsp;</span>
            Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von Daten zur
            Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen gestattet.
        </p>
        <p>
            <span><strong>&nbsp;</strong></span>
        </p>
        <p>
            <span>
                <strong>
                    Kontaktformular
                    <br />
                </strong>
            </span>
            Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem Anfrageformular
            inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von
            Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter. Die
            Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt somit ausschließlich auf Grundlage Ihrer
            Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Sie können diese Einwilligung jederzeit widerrufen. Dazu reicht
            eine formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
            Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt. Die von Ihnen im Kontaktformular eingegebenen
            Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen
            oder der Zweck für die Datenspeicherung entfällt (z.B. nach abgeschlossener Bearbeitung Ihrer Anfrage).
            Zwingende gesetzliche Bestimmungen – insbesondere Aufbewahrungsfristen – bleiben unberührt.
        </p>
        <p><strong>&nbsp;</strong></p>
        <p>
            <strong>
                Verarbeiten von Daten (Kunden- und Vertragsdaten)
                <br />
            </strong>
            Wir erheben, verarbeiten und nutzen personenbezogene Daten nur, soweit sie für die Begründung, inhaltliche
            Ausgestaltung oder Änderung des Rechtsverhältnisses erforderlich sind (Bestandsdaten). Dies erfolgt auf
            Grundlage von Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von Daten zur Erfüllung eines Vertrags oder
            vorvertraglicher Maßnahmen gestattet. Personenbezogene Daten über die Inanspruchnahme unserer Internetseiten
            (Nutzungsdaten) erheben, verarbeiten und nutzen wir nur, soweit dies erforderlich ist, um dem Nutzer die
            Inanspruchnahme des Dienstes zu ermöglichen oder abzurechnen.&nbsp; Die erhobenen Kundendaten werden nach
            Abschluss des Auftrags oder Beendigung der Geschäftsbeziehung gelöscht. Gesetzliche Aufbewahrungsfristen
            bleiben unberührt.
        </p>
        <p><strong>&nbsp;</strong></p>
        <p>
            <strong>Datenübermittlung bei Vertragsschluss für Dienstleistungen und digitale Inhalte</strong>
            Wir übermitteln personenbezogene Daten an Dritte nur dann, wenn dies im Rahmen der Vertragsabwicklung
            notwendig ist, etwa an das mit der Zahlungsabwicklung beauftragte Kreditinstitut. Eine weitergehende
            Übermittlung der Daten erfolgt nicht bzw. nur dann, wenn Sie der Übermittlung ausdrücklich zugestimmt haben.
            Eine Weitergabe Ihrer Daten an Dritte ohne ausdrückliche Einwilligung, etwa zu Zwecken der Werbung, erfolgt
            nicht. Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von Daten
            zur Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen gestattet.
        </p>
        <p>
            <span><strong>&nbsp;</strong></span>
        </p>
        <p>
            <span><strong>4. Analyse Tools und Werbung</strong></span>
        </p>
        <p>
            <span><strong>&nbsp;</strong></span>
        </p>
        <p>
            <span>
                <strong>
                    Google Analytics
                    <br />
                </strong>
            </span>
            Diese Website nutzt Funktionen des Webanalysedienstes Google Analytics. Anbieter ist die Google Inc., 1600
            Amphitheatre Parkway, Mountain View, CA 94043, USA. Google Analytics verwendet so genannte "Cookies". Das
            sind Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website
            durch Sie ermöglichen. Die durch den Cookie erzeugten Informationen über Ihre Benutzung dieser Website
            werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Die Speicherung
            von Google-Analytics-Cookies erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat
            ein berechtigtes Interesse an der Analyse des Nutzerverhaltens, um sowohl sein Webangebot als auch seine
            Werbung zu optimieren.&nbsp;
        </p>
        <p>
            <span><strong>&nbsp;</strong></span>
        </p>
        <p>
            <span>
                <strong>
                    IP Anonymisierung
                    <br />
                </strong>
            </span>
            Wir haben auf dieser Website die Funktion IP-Anonymisierung aktiviert. Dadurch wird Ihre IP-Adresse von
            Google innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens
            über den Europäischen Wirtschaftsraum vor der Übermittlung in die USA gekürzt. Nur in Ausnahmefällen wird
            die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Im Auftrag des
            Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten,
            um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der
            Internetnutzung verbundene Dienstleistungen gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von
            Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google
            zusammengeführt.
        </p>
        <p>
            <span><strong>&nbsp;</strong></span>
        </p>
        <p>
            <span>
                <strong>
                    Browser Plugin
                    <br />
                </strong>
            </span>
            Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software
            verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
            Funktionen dieser Website vollumfänglich werden nutzen können. Sie können darüber hinaus die Erfassung der
            durch den Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an
            Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie das unter dem folgenden Link
            verfügbare Browser-Plugin herunterladen und installieren:&nbsp;
            <a href="https://tools.google.com/dlpage/gaoptout?hl=de." target="_blank">
                https://tools.google.com/dlpage/gaoptout?hl=de.
            </a>
        </p>
        <p>
            <span><strong>&nbsp;</strong></span>
        </p>
        <p>
            <span>
                <strong>
                    Widerspruch gegen Datenerfassung
                    <br />
                </strong>
            </span>
            Sie können die Erfassung Ihrer Daten durch Google Analytics verhindern, indem Sie auf folgenden Link
            klicken:&nbsp;
        </p>
        <p><span>&nbsp;</span></p>
        <p>
            <a href="https://tools.google.com/dlpage/gaoptout?hl=de" target="_blank">
                <span>Google Analytics deaktivieren.</span>
                &nbsp;
            </a>
        </p>
        <p><span>&nbsp;</span></p>
        <p>
            <span>
                Es wird ein Opt-Out-Cookie gesetzt, der die Erfassung Ihrer Daten bei zukünftigen Besuchen dieser
                Website verhindert.
                <br />
            </span>
            Mehr Informationen zum Umgang mit Nutzerdaten bei Google Analytics finden Sie in der Datenschutzerklärung
            von Google:&nbsp;
        </p>
        <p><span>&nbsp;</span></p>
        <p>
            <span>
                <a href="https://support.google.com/analytics/answer/6004245?hl=de" target="_blank">
                    https://support.google.com/analytics/answer/6004245?hl=de
                </a>
            </span>
        </p>
        <p>
            <span><strong>&nbsp;</strong></span>
        </p>
        <p>
            <span>
                <strong>
                    Demografische Merkmale bei Google Analytics
                    <br />
                </strong>
            </span>
            Diese Website nutzt die Funktion “demografische Merkmale” von Google Analytics. Dadurch können Berichte
            erstellt werden, die Aussagen zu Alter, Geschlecht und Interessen der Seitenbesucher enthalten. Diese Daten
            stammen aus interessenbezogener Werbung von Google sowie aus Besucherdaten von Drittanbietern. Diese Daten
            können keiner bestimmten Person zugeordnet werden. Sie können diese Funktion jederzeit über die
            Anzeigeneinstellungen in Ihrem Google-Konto deaktivieren oder die Erfassung Ihrer Daten durch Google
            Analytics wie im Punkt “Widerspruch gegen Datenerfassung” dargestellt generell untersagen.
        </p>
        <p>
            <span><strong>&nbsp;</strong></span>
        </p>
        <p>
            <span><strong>5. Plugin und Tools</strong></span>
        </p>
        <p>
            <span><strong>&nbsp;</strong></span>
        </p>
        <p>
            <span>
                <strong>
                    Google Web Fonts
                    <br />
                </strong>
            </span>
            Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so genannte Web Fonts, die von Google
            bereitgestellt werden. Beim Aufruf einer Seite lädt Ihr Browser die benötigten Web Fonts in ihren
            Browsercache, um Texte und Schriftarten korrekt anzuzeigen. Zu diesem Zweck muss der von Ihnen verwendete
            Browser Verbindung zu den Servern von Google aufnehmen. Hierdurch erlangt Google Kenntnis darüber, dass über
            Ihre IP-Adresse unsere Website aufgerufen wurde. Die Nutzung von Google Web Fonts erfolgt im Interesse einer
            einheitlichen und ansprechenden Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes Interesse
            im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Wenn Ihr Browser Web Fonts nicht unterstützt, wird eine
            Standardschrift von Ihrem Computer genutzt. Weitere Informationen zu Google Web Fonts finden Sie unter
            <a
                href="https://developers.google.com/fonts/faq und in der Datenschutzerklärung von Google unter https://www.google.com/policies/privacy/."
                target="_blank"
            >
                https://developers.google.com/fonts/faq und in der Datenschutzerklärung von Google unter
                https://www.google.com/policies/privacy/.
            </a>
        </p>
        <p>
            <span><strong>&nbsp;</strong></span>
        </p>
        <p>
            <span>
                <strong>
                    Google Maps
                    <br />
                </strong>
            </span>
            Diese Seite nutzt über eine API den Kartendienst Google Maps. Anbieter ist die Google Inc., 1600
            Amphitheatre Parkway, Mountain View, CA 94043, USA. Zur Nutzung der Funktionen von Google Maps ist es
            notwendig, Ihre IP Adresse zu speichern. Diese Informationen werden in der Regel an einen Server von Google
            in den USA übertragen und dort gespeichert. Der Anbieter dieser Seite hat keinen Einfluss auf diese
            Datenübertragung. Die Nutzung von Google Maps erfolgt im Interesse einer ansprechenden Darstellung unserer
            Online-Angebote und an einer leichten Auffindbarkeit der von uns auf der Website angegebenen Orte. Dies
            stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Mehr Informationen zum Umgang
            mit Nutzerdaten finden Sie in der Datenschutzerklärung von Google:&nbsp;
            <a href="https://www.google.de/intl/de/policies/privacy/." target="_blank">
                https://www.google.de/intl/de/policies/privacy/.
            </a>
        </p>
    </div>
</template>
