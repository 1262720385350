import { useHttpClient } from "@/vf"
import type { AxiosResponse } from "axios"
import { reactive } from "vue"

type UploadStartCallback = (promise: Promise<any>) => any

export function useSimpleUpload(httpClientMethod: "post" | "postBg" | "put" | "putBg" = "post") {
    const http = useHttpClient()
    let url: string = ""
    let fieldName: string = ""
    let uploadStartCb: UploadStartCallback | null = null

    const uploadStatus: {
        name: string | null
        running: boolean
        error: boolean
        progress: number
    } = reactive({
        name: null,
        running: true,
        error: false,
        progress: 0,
    })

    const uploadInput = document.createElement("input")
    uploadInput.setAttribute("type", "file")
    uploadInput.addEventListener("change", () => {
        if (!uploadInput.files || !uploadInput.files.length) {
            // no files selected
            return
        }

        // prepare data and update view
        const fileName = uploadInput.files[0].name
        const fd = new FormData()
        fd.append(fieldName, uploadInput.files[0], fileName)

        uploadStatus.name = fileName
        uploadStatus.running = true
        uploadStatus.error = false
        uploadStatus.progress = 0

        let promiseResolve: (value: AxiosResponse<any, any> | PromiseLike<AxiosResponse<any, any>>) => void,
            promiseReject: (value: AxiosResponse<any, any> | PromiseLike<AxiosResponse<any, any>>) => void
        const promise = new Promise<AxiosResponse>((resolve, reject) => {
            promiseResolve = resolve
            promiseReject = reject
        })
        if (uploadStartCb) {
            uploadStartCb(promise)
        }

        http[httpClientMethod](url, fd, {
            onUploadProgress: event => {
                uploadStatus.progress = (event.loaded / (event.total ?? 1)) * 100
            },
        })
            .then(response => {
                uploadStatus.running = false
                uploadStatus.progress = 0
                promiseResolve(response)
            })
            .catch(response => {
                uploadStatus.running = false
                uploadStatus.progress = 0
                uploadStatus.error = true
                promiseReject(response)
            })
    })

    function upload(
        _url: string,
        _uploadStartCb: UploadStartCallback | null = null,
        accept = "*.*",
        _fieldName = "file",
    ) {
        url = _url
        fieldName = _fieldName
        uploadStartCb = _uploadStartCb
        uploadInput.setAttribute("accept", accept)
        // clear inputs file list (https://stackoverflow.com/questions/3144419/how-do-i-remove-a-file-from-the-filelist)
        uploadInput.value = ""
        uploadInput.click()
    }

    function asyncUpload(url: string, accept = "*.*", fieldName = "file"): Promise<AxiosResponse> {
        return new Promise((resolve, reject) => {
            upload(
                url,
                p => {
                    p.then(resolve).catch(reject)
                    return p
                },
                accept,
                fieldName,
            )
        })
    }

    return {
        uploadStatus,
        upload,
        asyncUpload,
    }
}
