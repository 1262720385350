<script setup lang="ts">
import CartList from "@/shop/components/checkout/CartList.vue"
import CheckoutTimeline from "@/shop/components/checkout/CheckoutTimeline.vue"
import { useShopDialogs } from "@/shop/composables/useShopDialogs"
import { useCartStore } from "@/shop/store/useCartStore"
import { useTooManyOrdersWarningStore } from "@/shop/store/useTooManyOrdersWarning"
import { ref } from "vue"
import { useRouter } from "vue-router"

function activate() {
    cartService
        .update({ validatePendingOrders: true })
        .then()
        .catch(e => {
            console.log("error", e)
            error.value = e.response.data.errorType
        })
}

async function gotoPayment() {
    if (!cartService.allSubitemsSelected) {
        dialogs.alert("Achtung", "Bitte wähle für alle Artikel die gewünschten Optionen (Packmittel und Integra) aus.")
        return
    }

    if (await tooManyOrdersWarning.noWarningOrDoAnyways()) {
        router.push({ name: "payment" })
    }
}
/*─────────────────────────────────────┐
│   composables                        │
└─────────────────────────────────────*/
const cartService = useCartStore()
const router = useRouter()
const dialogs = useShopDialogs()
const tooManyOrdersWarning = useTooManyOrdersWarningStore()
/*─────────────────────────────────────┐
│   refs                               │
└─────────────────────────────────────*/
// errors regarding non-availability of the cart itself (e.g. due to pending orders)
const error = ref<null | "pendingOrder" | "unpaidOrder">(null)
/*─────────────────────────────────────┐
│   setup                              │
└─────────────────────────────────────*/
activate()
</script>

<template>
    <div class="container my-5">
        <div class="small text-muted mb-5 pb-3">Home / Warenkorb</div>

        <div v-if="error === 'pendingOrder'" class="alert alert-danger">
            In deinem
            <RouterLink :to="{ name: 'my-account' }">Kundenprofil</RouterLink>
            befindet sich eine noch nicht geprüfte oder nicht bezahlte Bestellung. Du kannst diese wieder entfernen, um
            deine Bestellung neu aufzunehmen. Weitere Bestellungen sind sonst erst nach Bestellbestätigung möglich.
        </div>

        <div v-if="error === 'unpaidOrder'" class="alert alert-danger">
            Dein
            <RouterLink :to="{ name: 'my-account' }">Kundenprofil</RouterLink>
            weist noch offene Forderungen auf. Eine Bestellung ist erst wieder möglich, wenn diese beglichen sind. Melde
            dich gerne bei uns, wenn du Fragen hast.
        </div>

        <template v-if="!error">
            <h2>Übersicht</h2>
            <div class="mb-3 d-flex justify-content-between">
                <h1>Warenkorb</h1>

                <div class="text-right d-none d-md-block">
                    <a v-if="cartService.items.length" class="btn btn-primary" href="#" @click="gotoPayment()">
                        Weiter
                    </a>
                    <button v-if="!cartService.items.length" class="btn btn-primary" disabled>Weiter</button>
                </div>
            </div>

            <CheckoutTimeline :step="1" />
            <CartList is-cart />

            <div class="text-right">
                <a v-if="cartService.items.length" class="btn btn-primary" href="#" @click="gotoPayment()">Weiter</a>
                <button v-else class="btn btn-primary" disabled>Weiter</button>
            </div>
        </template>
    </div>
</template>
