<script setup lang="ts">
import { assetLink } from "@/common/util"
import ArticlesSidebar from "@/shop/components/ArticlesSidebar.vue"
import { useShopAppConfig, type AppConfigArticle } from "@/shop/composables"
import { useArticleFilterStore } from "@/shop/store"
import { $currency } from "@/vf"
import { useRouteQuery } from "@vueuse/router"
import { computed, ref, watchEffect } from "vue"

type Article = AppConfigArticle & {
    showSpace?: boolean
}

const { appConfig } = useShopAppConfig()

function parseValue(value: string) {
    return Number.parseFloat(value.replace("<", "").replace(".", "").replace(",", "."))
}

const articleGroups = computed<{ type: string; articles: Article[] }[]>(() => {
    let searchString = filterStore.search
    if (searchString) {
        searchString = searchString.toLowerCase()
    }
    const containsSearchString = (article: Article) => {
        if (!searchString) return true
        return (
            (article.name && article.name.toLowerCase().includes(searchString)) ||
            (article.text && article.text.toLowerCase().includes(searchString)) ||
            (article.brand && article.brand.name && article.brand.name.toLowerCase().includes(searchString)) ||
            (article.publicNamePrimary && article.publicNamePrimary.toLowerCase().includes(searchString)) ||
            (article.publicNameSecondary && article.publicNameSecondary.toLowerCase().includes(searchString)) ||
            (article.strain && article.strain.toLowerCase().includes(searchString)) ||
            (article.species && article.species.toLowerCase().includes(searchString))
        )
    }
    const filterThc = (article: Article) => {
        if (!filterStore.thc) {
            // no thc filter selected
            return true
        }

        const thc = parseValue(article.thc ?? "0") ?? 0
        if (isNaN(thc)) {
            // article has invalid formatted thc value, show it anyway (?)
            return true
        }

        return filterStore.thc.min <= thc && thc < filterStore.thc.max
    }

    const filterPrices = (article: Article) => {
        if (!filterStore.priceCategory || !appConfig.value.showPrices) {
            // prices are only visible to logged in customers
            return true
        }

        return filterStore.priceCategory === article.colorCategory
    }

    const filterTerpenes = (article: Article) => {
        if (filterStore.terpenes.length === 0) {
            return true
        }

        for (const terpene of filterStore.terpenes) {
            if (!article.terpeneProfile?.includes(terpene)) {
                return false
            }
        }

        return true
    }

    // filter the results
    const results = appConfig.value.articles.filter(
        article =>
            filterStore.type === article.type &&
            (!filterStore.brand || filterStore.brand === article.brand?.id) &&
            (!filterStore.species || filterStore.species === article.species) &&
            filterTerpenes(article) &&
            filterThc(article) &&
            filterPrices(article) &&
            containsSearchString(article),
    )

    const byType: Record<string, Article[]> = {}

    for (const article of results) {
        article.type ??= "standard" // should always be set, just to make sure the type is not undefined
        if (!byType[article.type]) byType[article.type] = []
        byType[article.type].push(article)
    }
    const groups: { type: string; articles: Article[] }[] = []

    for (const type of Object.keys(byType)) {
        const articles = byType[type].sort((a: Article, b: Article) => {
            if (orderBy.value !== "default") {
                // when sorting by user defined order, ignore promotions
                return compareUserSortOrder(a, b)
            }

            // always show promoted articles first
            const aPosition = a.isShowcasePromoted ? 0 : 50
            const bPosition = b.isShowcasePromoted ? 0 : 50

            if (aPosition === bPosition) {
                // apply user defined sort method
                return compareUserSortOrder(a, b)
            }

            if (aPosition > bPosition) {
                return 1
            }

            return -1
        })

        // make spaces between brands with different positions
        // let lastBrand = null
        // for (const article of articles) {
        //     const brand = article.brand ? article.brand.position : null

        //     if (lastBrand !== null && brand !== lastBrand) {
        //         article.showSpace = true
        //     }

        //     lastBrand = brand
        // }

        // add spaces between promoted and not promoted articles
        if (articles[0].isShowcasePromoted && orderBy.value === "default") {
            for (const article of articles) {
                if (!article.isShowcasePromoted) {
                    article.showSpace = true
                    break
                }
            }
        }

        groups.push({ type, articles })
    }

    return groups
})

const filterStore = useArticleFilterStore()
const searchQuery = useRouteQuery("search")

watchEffect(() => {
    filterStore.search = searchQuery.value instanceof Array ? searchQuery.value[0] : searchQuery.value ?? undefined
})

function clearSearch() {
    searchQuery.value = null
}

/*─────────────────────────────────────┐
│  sorting                             │
└─────────────────────────────────────*/
const orderBy = ref("default")

function sortName(a: Article, b: Article) {
    return a.name?.localeCompare(b.name ?? "") ?? 0
}

// sort by field, when both have the same value, sort by name instead
function sortValue(a: number, b: number, desc: boolean, fallback: () => number) {
    if (a === b) {
        return fallback()
    }

    return desc ? b - a : a - b
}

function sortPromotion(a: Article, b: Article, fallback: () => number) {
    const aPosition = a.isShowcasePromoted ? 0 : 50
    const bPosition = b.isShowcasePromoted ? 0 : 50

    if (aPosition === bPosition) {
        return fallback()
    }

    return bPosition - aPosition
}

function compareUserSortOrder(a: Article, b: Article) {
    // it not logged in, always use default sorting
    if (orderBy.value === "default" || !appConfig.value.user) {
        const brandNameCompare = a.brand?.name?.localeCompare(b.brand?.name ?? "")

        if (brandNameCompare !== 0) {
            return brandNameCompare ?? 0
        }

        return sortName(a, b)
    }

    const desc = orderBy.value.endsWith("desc")
    // when value equals, sort by promotion and then by name
    const fallback = () => sortPromotion(a, b, () => sortName(a, b))

    if (orderBy.value.startsWith("price")) {
        const priceA = a.price / (a.packageAmount ?? 1) ?? 0
        const priceB = b.price / (b.packageAmount ?? 1) ?? 0

        if (a.name?.match(/aurora|bakerstreet|schwarte|natural/i)) {
            console.log(a.name, priceA)
        }

        return sortValue(priceA, priceB, desc, fallback)
    }

    if (orderBy.value.startsWith("thc")) {
        const thcA = parseValue(a.thc ?? "0") ?? 0
        const thcB = parseValue(b.thc ?? "0") ?? 0

        return sortValue(thcA, thcB, desc, fallback)
    }

    return 0
}
</script>

<template>
    <div class="container">
        <div class="row">
            <div class="col-md-3 col-xl-2">
                <ArticlesSidebar />
            </div>
            <div class="col-md-9 col-xl-10">
                <div class="breadcrumb mt-3">
                    <RouterLink class="breadcrumb-item" :to="{ name: 'home' }">Home</RouterLink>
                    <div class="breadcrumb-item">Sortiment</div>
                </div>

                <template v-if="articleGroups.length == 0">
                    <h1 class="mt-4 page-products-heading">Keine Artikel gefunden</h1>
                    <span v-if="filterStore.search">
                        Suche nach "{{ filterStore.search }}"
                        <a @click.prevent="clearSearch()">
                            <i class="fas fa-times"></i>
                        </a>
                    </span>
                </template>
                <template v-for="articleGroup in articleGroups" :key="articleGroup.id">
                    <div class="d-flex justify-content-between align-items-md-center flex-md-row flex-column">
                        <div>
                            <h1 v-if="articleGroup.type === 'cannabis'" class="mt-4 page-products-heading">
                                <img src="@/shop/assets/cannabis.svg" width="48" height="48" />
                                Blüten
                            </h1>
                            <h1 v-if="articleGroup.type === 'essence'" class="mt-4 page-products-heading">
                                <img src="@/shop/assets/essence.svg" width="48" height="48" />
                                Extrakte
                            </h1>
                            <h1 v-if="articleGroup.type === 'merch'" class="mt-4 page-products-heading">
                                <img src="@/shop/assets/merch.svg" width="48" height="48" />
                                Merch
                            </h1>
                            <h1 v-if="articleGroup.type === 'standard'" class="mt-4 page-products-heading">
                                <img src="@/shop/assets/standard.svg" width="48" height="48" />
                                Zubehör
                            </h1>
                            <span v-if="filterStore.search">
                                Suchergebnisse für "{{ filterStore.search }}"
                                <a @click.prevent="clearSearch()">
                                    <i class="fas fa-times"></i>
                                </a>
                            </span>
                        </div>
                        <div v-if="appConfig.user" class="text-right">
                            <select v-model="orderBy" class="custom-select w-auto d-inline">
                                <option value="default">Standard-Sortierung</option>
                                <option value="price">Preis aufsteigend</option>
                                <option value="price-desc">Preis absteigend</option>
                                <option value="thc">THC aufsteigend</option>
                                <option value="thc-desc">THC absteigend</option>
                            </select>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <template v-for="article in articleGroup.articles" :key="article.id">
                            <div v-if="article.showSpace" class="col-12 py-5"></div>

                            <RouterLink
                                class="col-md-6 col-xl-4 text-decoration-none d-flex flex-row"
                                :to="{
                                    name: 'article',
                                    params: { urlId: `${article.urlId}`, slug: `${article.slug}` },
                                }"
                            >
                                <div class="product-card" :class="{ 'product-card-inactive': !article.inStock }">
                                    <div class="d-flex justify-content-between">
                                        <div>
                                            <div
                                                v-if="article.price"
                                                :class="'color-category color-category-' + article.colorCategory"
                                            >
                                                {{ $currency(article.price / (article.packageAmount ?? 1)) }} /
                                                {{ article.unit }}
                                            </div>
                                        </div>

                                        <div
                                            v-if="article.type !== 'standard' && article.type !== 'merch'"
                                            class="product-card-type"
                                        >
                                            <span v-if="article.type === 'cannabis'">{{ article.species }}</span>
                                            <span v-if="article.type === 'essence'">Extrakt</span>
                                            <img src="@/shop/assets/cannabis.svg" width="16" height="16" class="ml-2" />
                                        </div>
                                    </div>

                                    <div class="product-card-image">
                                        <img
                                            v-if="(article.shopImages?.length ?? 0) == 0 && article.showImageInList"
                                            src="@/shop/assets/placeholder.jpg"
                                        />
                                        <img
                                            v-if="(article.shopImages?.length ?? 0) > 0 && article.showImageInList"
                                            :src="assetLink('/uploads/media/' + article.shopImages[0].imageName)"
                                        />
                                    </div>

                                    <div class="product-card-brand">
                                        {{ article.publicNamePrimary }}
                                    </div>

                                    <div class="product-card-name">
                                        {{ article.name }}
                                    </div>

                                    <div
                                        v-if="article.type === 'cannabis' || article.type === 'essence'"
                                        class="product-card-details"
                                    >
                                        THC {{ article.thc }} %* &middot; CBD {{ article.cbd }} %*
                                    </div>

                                    <div class="product-card-hover">
                                        <div class="mb-3">
                                            <button class="btn btn-primary">Zum Produkt</button>
                                        </div>

                                        <div>
                                            <div
                                                style="width: 0.75rem; height: 0.75rem; border-radius: 50%"
                                                :class="
                                                    (article.inStock ? 'bg-success' : 'bg-danger') + ' d-inline-block'
                                                "
                                            ></div>
                                            {{ article.inStock ? "Lieferbar" : "Nicht Lieferbar" }}
                                            <div v-if="!article.inStock" class="font-weight-bold mb-2">
                                                {{ article.notInStockText || "Liefertermin unbekannt" }}
                                            </div>
                                        </div>
                                        <div v-if="article.type === 'cannabis' || article.type === 'essence'">
                                            Gebindegröße: {{ article.packageAmount }} {{ article.unit }}
                                        </div>
                                    </div>
                                </div>
                            </RouterLink>
                        </template>
                    </div>
                </template>

                <i v-if="articleGroups.length > 0">* Werte der aktuellen Charge</i>
            </div>
        </div>
    </div>
</template>
