export function $articleType(s: string): string {
    return (
        {
            standard: "Zubehör",
            cannabis: "Blüten",
            essence: "Extrakte",
            merch: "Merch",
        }[s] ?? "Sonstiges"
    )
}

export function $articleUnit(s: string): string {
    return (
        {
            g: "Gramm",
            ml: "Milliliter",
            stk: "Stück",
        }[s] ?? "Einheit"
    )
}
