import { useRouter } from "vue-router"
import { LoginOverlay } from "../components/overlays"
import { useOverlayStore } from "../store"
import { useShopAppConfig } from "./useShopAppConfig"

type LoginResult = "success" | "cancelled" | "closed"

export function useAuthentication() {
    const overlay = useOverlayStore()
    const { appConfig } = useShopAppConfig()
    const router = useRouter()

    /**
     * Requests the user to log in. If the user is already logged in, the function resolves immediately. If the user
     * navigates away from within the login overlay, the promise resolves with "cancelled". If the overlay is closed
     * without any action, the promise resolves with "closed".
     *
     * If you want to perform an action when the user is logged in or the overlay is closed, just check for return value
     * "success". If you need to perform a navigation when the user cancels the login, e. g. for a route guard, check
     * for "success" and "closed". Usually you don't need to check for "cancelled" as the user is already on the route
     * he wanted to navigate to.
     *
     * To simply trigger a login with the default behaviour (go to my account page after login, use openLogin() instead.)
     */
    function login(): Promise<LoginResult> {
        if (appConfig.value?.user) {
            return Promise.resolve("success")
        }

        return new Promise(resolve => {
            overlay.open(LoginOverlay, {
                onAuthenticated() {
                    resolve("success")
                },
                onCancelled() {
                    resolve("cancelled")
                },
                onClosed() {
                    resolve("closed")
                },
            })
        })
    }

    /**
     * Open the login overlay with default behaviour (navigating to my account page after login, ignoring close and
     * cancel events). If you need to handle these events, use login() instead.
     */
    async function openLogin(): Promise<void> {
        if (await successfulLogin()) {
            router.push({ name: "my-account" })
        }
    }

    /**
     * The same as (await login()) === "success". Can be used if you are only interested in the success outcome and
     * don't care if the user cancelled or closed the login. Resolves to true if the user is logged in, false if
     * cancelled or closed (which you should generally ignore in this case or use login() instead)
     *
     * Usage:
     * if (await auth.successfulLogin()) {
     *    router.push("/wherever")
     * }
     */
    async function successfulLogin(): Promise<boolean> {
        return (await login()) === "success"
    }

    return { login, successfulLogin, openLogin }
}
