import type { AxiosError, AxiosResponse } from "axios"
import { ref } from "vue"
import { useHttpClient } from ".."

export interface UploadProgress {
    uploadedBytes: number
    totalBytes: number
    progress: number
    /** Use this when you accept multiple files. When only accepting one file, this will only contain the one selected file name */
    fileNames: string[]
    /** When multiple is set to true, this will contain a comma separated list of file names. Use fileNames if you need an array. */
    fileName: string
}

export interface UseOploadOptions {
    multiple?: boolean
    /**
     * Accepted file pattern as in input[type=file] accept attribute (.jpg, .png, image/*)
     *
     * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/accept
     */
    accept?: string
    url: string
    fieldName?: string
    method?: "post" | "put"
}

function normalizeOptions(options: UseOploadOptions): Required<UseOploadOptions> {
    return {
        accept: options.accept ?? "*.*",
        fieldName: options.fieldName ?? "file",
        multiple: options.multiple ?? false,
        url: options.url,
        method: options.method ?? "post",
    }
}

export function useUpload<ResponseType, ErrorResponseType = any>(uploadOptions: UseOploadOptions) {
    const http = useHttpClient()
    const options = normalizeOptions(uploadOptions)

    let resolveStarted: (progress: void) => void
    let resolveFinished: (response: AxiosResponse<ResponseType>) => void
    let resolveFailed: (response: AxiosError<ErrorResponseType>) => void

    const finished = new Promise<AxiosResponse<ResponseType>>((resolve, reject) => {
        resolveFinished = resolve
        resolveFailed = reject
    })
    const started = new Promise<void>(resolve => (resolveStarted = resolve))

    const uploadedBytes = ref(0)
    const totalBytes = ref(0)
    const progress = ref(0)
    const fileName = ref<string>()
    const fileNames = ref<string[]>()
    const uploading = ref(false)
    const failed = ref(false)
    const error = ref<AxiosError<ErrorResponseType>>()

    const uploadInput = document.createElement("input")
    uploadInput.setAttribute("type", "file")
    uploadInput.addEventListener("change", () => {
        if (!uploadInput.files || !uploadInput.files.length) {
            // no files selected
            return
        }

        // prepare data and update view
        const fd = new FormData()

        if (options.multiple && !options.fieldName.endsWith("[]")) {
            options.fieldName += "[]"
        }

        Array.from(uploadInput.files).forEach(file => {
            fd.append(options.fieldName, file, file.name)
        })

        fileNames.value = Array.from(uploadInput.files).map(file => file.name)
        fileName.value = fileNames.value.join(", ")

        uploading.value = true

        resolveStarted()

        http[options.method]<ResponseType>(options.url, fd, {
            onUploadProgress: event => {
                uploadedBytes.value = event.loaded
                totalBytes.value = event.total ?? 0
                progress.value = (event.loaded / (event.total ?? 1)) * 100
            },
        })
            .then(response => {
                uploading.value = false

                resolveFinished(response)
            })
            .catch((response: AxiosError<ErrorResponseType>) => {
                error.value = response
                failed.value = true
                uploading.value = false

                resolveFailed(response)
            })
    })

    uploadInput.setAttribute("accept", options.accept)
    uploadInput.toggleAttribute("multiple", options.multiple)
    // clear inputs file list (https://stackoverflow.com/questions/3144419/how-do-i-remove-a-file-from-the-filelist)
    uploadInput.value = ""
    uploadInput.click()

    return {
        finished,
        started,
        uploadedBytes,
        totalBytes,
        progress,
        fileName,
        fileNames,
        uploading,
        failed,
        error,
    }
}
