<script setup lang="ts">
import CartListItem from "@/shop/components/checkout/CartListItem.vue"
import { useShopAppConfig } from "@/shop/composables"
import { useCartStore } from "@/shop/store/useCartStore"
import { $currency } from "@/vf"
import PriceSpinner from "./PriceSpinner.vue"
/*─────────────────────────────────────┐
│   props                              │
└─────────────────────────────────────*/
withDefaults(
    defineProps<{
        error?: {
            errorType?: "pendingOrder" | "file" | "invalidZipForCarrierService" | "outOfStock"
            articles?: string[]
        }
        isCart?: boolean
    }>(),
    {
        error: undefined,
        isCart: false,
    },
)
/*─────────────────────────────────────┐
│   composables                        │
└─────────────────────────────────────*/
const cartStore = useCartStore()

const { appConfig } = useShopAppConfig()
</script>

<template>
    <div v-if="!cartStore.items.length" class="py-5 text-center text-muted">
        Du hast noch keine Artikel in Deinem Warenkorb
    </div>

    <template v-for="item in cartStore.items" :key="item">
        <CartListItem v-bind="{ item, isCart, error }" />
    </template>

    <table class="table table-borderless my-3">
        <template v-if="true || appConfig.showPrices || !isCart">
            <template v-for="(item, $index) in cartStore.serverItems" :key="$index">
                <tr v-if="true || item.type !== 'fee' || !isCart">
                    <td colspan="4" class="text-right">
                        {{ item.name }}
                    </td>
                    <td class="text-right">
                        {{ $currency(item.totalPrice) }}
                    </td>
                </tr>
            </template>
            <template v-if="(cartStore.totalTax ?? 0) > 0">
                <tr>
                    <td colspan="4" class="text-right">Gesamtsumme ohne MwSt.</td>
                    <td class="text-right">
                        <PriceSpinner
                            :price="
                                cartStore.totalPrice === undefined
                                    ? undefined
                                    : cartStore.totalPrice - (cartStore.totalTax ?? 0)
                            "
                        ></PriceSpinner>
                    </td>
                </tr>
                <tr v-for="item in cartStore.totalTaxes" :key="item.vat">
                    <td colspan="4" class="text-right">zzgl. {{ item.vat }}% MwSt.</td>
                    <td class="text-right">
                        <PriceSpinner :price="cartStore.totalPrice === undefined ? undefined : item.tax"></PriceSpinner>
                    </td>
                </tr>
            </template>
            <tr>
                <td colspan="5"></td>
            </tr>
            <tr v-if="cartStore.totalPaymentPrice !== cartStore.totalPrice">
                <td colspan="4" class="text-right">Zwischensumme</td>
                <td class="text-right" style="min-width: 30%">
                    <PriceSpinner :price="cartStore.totalPrice"></PriceSpinner>
                </td>
            </tr>
            <tr v-if="cartStore.usedBalance !== 0" class="text-primary">
                <td colspan="4" class="text-right">Verwendetes Guthaben</td>
                <td class="text-right">
                    {{ $currency(cartStore.usedBalance) }}
                </td>
            </tr>
            <tr class="font-weight-bold">
                <td colspan="4" class="text-right">Gesamtsumme</td>
                <td class="text-right" style="min-width: 30%">
                    <PriceSpinner :price="cartStore.totalPaymentPrice"></PriceSpinner>
                </td>
            </tr>
            <tr v-if="isCart">
                <td colspan="5" class="text-right">ggf. zzgl. Versandkosten</td>
            </tr>
        </template>
        <template v-if="appConfig.user.payType === 'public_insurance' && isCart && cartStore.containsPrescriptionItems">
            <tr>
                <td colspan="5" class="text-right">Kosten: {{ $currency(1000) }} Gebühr pro Rezept.</td>
            </tr>
        </template>
    </table>
</template>
