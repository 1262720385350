<script setup lang="ts">
import { $prescriptionTransmission } from "@/common/util/converter"
import CartList from "@/shop/components/checkout/CartList.vue"
import CheckoutTimeline from "@/shop/components/checkout/CheckoutTimeline.vue"
import { useShopAppConfig } from "@/shop/composables"
import { useShopDialogs } from "@/shop/composables/useShopDialogs"
import { useCartStore } from "@/shop/store/useCartStore"
import type { AxiosError } from "axios"
import { ref } from "vue"
import { useRouter } from "vue-router"
/*─────────────────────────────────────┐
│   props                              │
└─────────────────────────────────────*/
/*─────────────────────────────────────┐
│   functions                          │
└─────────────────────────────────────*/
async function activate(): Promise<void> {
    if (!cartStore.shippingMethod) {
        router.push({ name: "delivery" })
        return
    }
    await cartStore.update().catch()
    for (const s of appConfig.value.shippingMethods) {
        if (s.name == cartStore.shippingMethod) {
            shippingName.value = s.title
            break
        }
    }
    for (const s of appConfig.value.payment) {
        if (s.name == cartStore.paymentMethod) {
            paymentName.value = s.title
            break
        }
    }
}

async function submitOrder(): Promise<void> {
    if (!checkedTermsAndConditions.value) {
        dialogs.alert("Achtung", "Bitte akzeptiere die AGB um fortzufahren")
        return
    }
    if (!checkedReceiptNotice.value && cartStore.totalCannabisAmount > 0) {
        dialogs.alert("Achtung", "Bitte bestätige den Hinweis zum Originalrezept um fortzufahren")
        return
    }
    if (!checkedPaymentInfo.value) {
        dialogs.alert(
            "Achtung",
            "Bitte bestätige, dass Du Deine Zahlung erst vornehmen wirst wenn wir Deine Bestellung bestätigt haben.",
        )
        return
    }

    error.value = undefined
    loading.value = true

    try {
        const data = (await cartStore.submitOrder()).data
        if (data.status == "wait_for_payment" && data.url) {
            window.location.href = data.url
        } else {
            router.push({ name: "confirmation-order", params: { order: data.orderId } })
            cartStore.clear()
        }
    } catch (err) {
        const e = err as AxiosError<typeof error.value>
        error.value = e.response?.data
        window.scrollTo({ top: 0 })
    }

    loading.value = false
}

/*─────────────────────────────────────┐
│   composables                        │
└─────────────────────────────────────*/
const { appConfig } = useShopAppConfig()
const cartStore = useCartStore()
const router = useRouter()
const dialogs = useShopDialogs()
/*─────────────────────────────────────┐
│   refs                               │
└─────────────────────────────────────*/
const error = ref<{
    errorType: "pendingOrder" | "file" | "invalidZipForCarrierService" | "outOfStock"
    article?: string
}>()
const shippingName = ref<string>()
const paymentName = ref<string>()
const loading = ref(false)
const checkedTermsAndConditions = ref(false)
const checkedReceiptNotice = ref(false)
const checkedPaymentInfo = ref(false)
/*─────────────────────────────────────┐
│   setup                              │
└─────────────────────────────────────*/
await activate()
</script>

<template>
    <div class="container my-5">
        <div class="small text-muted mb-5 pb-3">Home / Warenkorb</div>

        <h2>Übersicht</h2>
        <div class="mb-3 d-flex justify-content-between">
            <h1>Abschluss</h1>
        </div>

        <CheckoutTimeline :step="6" />

        <div v-if="error && error.errorType" class="alert alert-danger mt-4">
            <div v-if="error.errorType === 'file'">Bei den Rezeptuploads ist ein Fehler aufgereten.</div>
            <div v-if="error.errorType === 'outOfStock'">
                Produkte sind nicht oder nicht in ausreichender Menge auf Lager.
            </div>
            <div v-if="error.errorType === 'invalidZipForCarrierService'">
                Leider können wir die gewählte Adresse nicht mit unserem Botendienst beliefern. Bitte wähle eine andere
                Versandart.
            </div>
            <div v-if="error.errorType === 'pendingOrder'">
                In Deinem
                <RouterLink :to="{ name: 'my-account' }">Kundenprofil</RouterLink>
                befindet sich eine noch nicht geprüfte oder nicht bezahlte Bestellung. Du kannst diese wieder entfernen,
                um Deine Bestellung neu aufzunehmen. Weitere Bestellungen sind sonst erst nach Bestellbestätigung
                möglich.
            </div>
        </div>

        <CartList :error="error" />

        <div class="row">
            <div class="col-md-4">
                <h3>Rechnungsadresse</h3>
                {{ appConfig.user.firstName }} {{ appConfig.user.lastName }}
                <br />
                {{ appConfig.user.street }}
                <br />
                {{ appConfig.user.zip }} {{ appConfig.user.city }}
            </div>
            <div class="col-md-4 mt-4 mt-md-0">
                <h3>Lieferadresse</h3>
                <div v-if="cartStore.shippingMethod != 'pickup' && cartStore.shippingMethod !== 'pickup_service'">
                    {{ cartStore.shippingAddress.firstName }} {{ cartStore.shippingAddress.lastName }}
                    <br />
                    {{ cartStore.shippingAddress.street }}
                    <br />
                    {{ cartStore.shippingAddress.zip }} {{ cartStore.shippingAddress.city }}
                </div>
                <div v-else>Abholung in der Apotheke</div>
            </div>
            <div class="col-md-4 mt-4 mt-md-0">
                <h3>Informationen</h3>

                <p>
                    <b class="d-block">Gewählte Zahlungsart:</b>
                    {{ paymentName }}
                    <br />
                </p>

                <p>
                    <b class="d-block">Versandart:</b>
                    {{ shippingName }}
                    <br />
                    <span
                        v-for="(option, $index) in cartStore.shippingOptions[cartStore.shippingMethod!]"
                        :key="$index"
                    >
                        + {{ option }}
                    </span>
                </p>

                <p v-if="cartStore.prescriptionTransmission">
                    <b class="d-block">Rezept-Art:</b>
                    {{ $prescriptionTransmission(cartStore.prescriptionTransmission) }}
                </p>
            </div>
        </div>

        <div class="my-4">
            <label class="my-4 my-md-2 d-flex align-items-start">
                <input v-model="checkedTermsAndConditions" type="checkbox" class="mt-1 mr-1" />
                <div>
                    Ich stimme den
                    <RouterLink :to="{ name: 'legal-toc' }">Allgemeinen Geschäftsbedingen</RouterLink>
                    zu.
                </div>
            </label>

            <label v-if="cartStore.totalCannabisAmount > 0" class="my-4 my-md-2 d-flex align-items-start">
                <input v-model="checkedReceiptNotice" type="checkbox" class="mt-1 mr-1" />
                <div>
                    Ich habe zur Kenntnis genommen, dass ich das Originalrezept wie von mir gewählt einreiche:
                    {{ $prescriptionTransmission(cartStore.prescriptionTransmission ?? "paper/letter") }}
                </div>
            </label>

            <label class="my-4 my-md-2 d-flex align-items-start">
                <input v-model="checkedPaymentInfo" type="checkbox" class="mt-1 mr-1" />
                <div>
                    Ich bestätige, dass ich die Zahlung erst vornehmen werde, wenn die Bestellung von der Apotheke
                    bestätigt wurde.
                </div>
            </label>
        </div>

        <div class="text-right d-flex justify-content-end align-items-center">
            <i v-if="loading" class="fa fa-fw fa-spin fa-circle-notch mr-3"></i>

            <button v-if="!appConfig.user" class="btn btn-primary" disabled>Bestellen</button>
            <button v-if="appConfig.user" class="btn btn-primary" :disabled="loading" @click="submitOrder()">
                Bestellen
            </button>
        </div>
    </div>
</template>
