<script setup lang="ts">
import CheckoutTimeline from "@/shop/components/checkout/CheckoutTimeline.vue"
import { useCartStore } from "@/shop/store/useCartStore"
import { useRouter } from "vue-router"
import PrescriptionWizard from "./prescription/PrescriptionWizard.vue"
/*─────────────────────────────────────┐
│   composables                        │
└─────────────────────────────────────*/
const cartStore = useCartStore()
const router = useRouter()
/*─────────────────────────────────────┐
│   refs                               │
└─────────────────────────────────────*/
/*─────────────────────────────────────┐
│   setup                              │
└─────────────────────────────────────*/

if (!cartStore.shippingMethod) {
    router.push({ name: "delivery" })
}
</script>

<template>
    <div class="container my-5">
        <div class="small text-muted mb-5 pb-3">Home / Warenkorb</div>

        <h2>Übersicht</h2>
        <div class="mb-3">
            <h1>Rezeptupload</h1>
        </div>

        <CheckoutTimeline :step="5" />

        <div v-if="!cartStore.containsPrescriptionItems" class="text-center py-5">
            Du hast keine rezeptpflichtigen Artikel in Deinem Warenkorb

            <div class="text-right">
                <RouterLink class="btn btn-primary" :to="{ name: 'checkout' }">Weiter</RouterLink>
            </div>
        </div>

        <div v-if="cartStore.containsPrescriptionItems" class="mt-3">
            <PrescriptionWizard></PrescriptionWizard>
        </div>
    </div>
</template>
