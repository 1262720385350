<script setup lang="ts">
import { useOverlayStore } from "../store"

const overlayStore = useOverlayStore()
</script>

<template>
    <div class="overlay-container">
        <component :is="overlayStore.component" v-if="overlayStore.active" :props="overlayStore.props" />
    </div>
</template>
